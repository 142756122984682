import { Content } from 'src/app/models/content.model';

export const INFOGRAFIA_REQUISITOS_PARTICIPACION: Content = new Content({
   idContent: '4f979b0f1025f5d2a7a092be90fcce3a',
   order: 1,
   title: '',
   content: `
    <div class="row">
        <div class="col-12">
          <img src="../../../assets/infografias/inmujeres-manual-b-graficos4.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </div>
    </div>
   `,
   subContents: []
});
