import { Content } from 'src/app/models/content.model';

export const MEDIDA_5_OBJETIVO: Content = new Content({
   idContent: '4d5ec58a5ab3faa71b0e605fd56abb7f',
   order: 1,
   title: 'Objetivo',
   content: `
    Impactar positivamente en el ámbito familiar de las personas que conforman el centro de trabajo, mediante la sensibilización en igualdad, igualdad laboral y no discriminación, dirigiendo diversas acciones a las personas integrantes de los hogares.
   `,
   subContents: []
});
