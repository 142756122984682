import { Component } from '@angular/core';

import { MainBannerService } from 'src/app/services/main-banner/main-banner.service';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  constructor(
    public mainBannerService: MainBannerService,
  ) {
  }
}
