import { Content } from 'src/app/models/content.model';

export const MEDIDA_1_OBJETIVO: Content = new Content({
   idContent: 'e214bef87b0d9ea3ca0c10b04edcd492',
   order: 1,
   title: 'Objetivo',
   content: `
    Demostrar que en los centros de trabajo exista la diversidad, conformada por hombres y mujeres, brindando a este último sector de la población mayor facilidad en el acceso al empleo, que se vea reflejada en la contratación de al menos el 40% del personal del mismo sexo.
   `,
   subContents: []
});
