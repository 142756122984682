import { Content } from 'src/app/models/content.model';

export const MEDIDA_5_EVIDENCIAS: Content = new Content({
   idContent: '6cf4518162e8d71a8dab9bc97d01c3ff',
   order: 1,
   title: 'Conformación de evidencias',
   content: `
    El centro de trabajo deberá generar como evidencias:
    <br><br>
    <ul>
      <li>Documentos que comprueben la planeación de estas acciones o actividades.</li>
      <li>Documentos probatorios de la difusión que incluya a todo el personal, con tiempo de anticipación, de la invitación a participar en ellas (convocatorias e invitaciones).</li>
      <li>Documentos que comprueben la realización de estas acciones o actividades (fotografías, videos, programas de actividades, listas de asistencia).</li>
      </ul>
   `,
   subContents: []
});
