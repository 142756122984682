import { Component, OnInit, Input } from '@angular/core';

import { Topic } from 'src/app/models/topic.model';

@Component({
  selector: 'app-topic-item',
  templateUrl: './topic-item.component.html',
  styleUrls: ['./topic-item.component.scss']
})

export class TopicItemComponent {

  @Input() topic?: Topic;
  @Input() idDoc: string = '';
  @Input() index: number = 0;

}
