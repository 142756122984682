import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-12/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-12/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-12/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-12/herramientas.data';
import { REQUISITO_NO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/no-critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_12_TOPIC: Topic = new Topic({
   idTopic: '2c863a2e0456e6b1bc6fda114f1c31ca',
   order: 1,
   title: 'Requisito 12: Acciones de corresponsabilidad',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_NO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.sixth
});