import { Content } from 'src/app/models/content.model';


export const REQUISITOS_GENERALES_CONTENT: Content = new Content({
   idContent: 'd20cc71f8654dfde4605d98da97b4b7c',
   order: 1,
   title: 'Requisitos generales de certificación o no críticos',
   content: `
   Una vez que se ha cumplido con los requisitos críticos, estos temas son evaluados para alcanzar el resto del puntaje y obtener la calificación necesaria para certificarse. 
   <br><br>
   La calificación mínima para obtener la certificación es de 70 puntos. 
   <br><br>
   <ul>
      <li>Política de igualdad laboral</li>
      <li>Comité de vigilancia</li>
      <li>Proceso de reclutamiento y selección</li>
      <li>Auditoría interna</li>
      <li>Clima laboral</li>
      <li>Código de ética</li>
   </ul>
   `,
   subContents: [
      
   ]
});