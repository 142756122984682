import { Component, OnInit, Input } from '@angular/core';

import { Content } from 'src/app/models/content.model';

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})

export class ContentsComponent {

  @Input() contents: Array<Content> = []

  ngOnInit(): void {
    
  }

}
