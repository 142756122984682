import { Content } from 'src/app/models/content.model';

export const MEDIDA_5_DESCRIPCION: Content = new Content({
   idContent: '36e0099cfde259ab27654422462d06f3',
   order: 1,
   title: 'Descripción',
   content: `
    La igualdad y no discriminación en el ámbito laboral al interior de los centros de trabajo, tiene el objetivo no solo de impactar en esta esfera al personal que  lo conforma, sino que, paulatinamente se transversalice generando impacto en la vida personal y familiar de quienes conforman al mismo.
    <br><br>
    Se deben generar actividades o acciones las cuales contemplen dentro de su planeación la promoción, difusión y en su caso, sensibilización en los temas de igualdad laboral y no discriminación. Asimismo, se debe difundir con anticipación a la totalidad del personal del centro de trabajo la invitación a participar de estas actividades o acciones, por el medio que el centro de trabajo elija.
   `,
   subContents: []
});