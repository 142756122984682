import { Content } from 'src/app/models/content.model';

export const MEDIDA_4_EVIDENCIAS: Content = new Content({
   idContent: '6cf4518162e8d71a8dab9bc97d01c3ff',
   order: 1,
   title: 'Conformación de evidencias',
   content: `
    El centro de trabajo deberá generar como evidencias:
    <br><br>
    <ul>
      <li>Demostrar mediante nombramiento oficial, la existencia de una figura mediadora u ombudsman, así como el documento en donde se detallen sus funciones y atribuciones.</li>
      <li>Presentar un informe anual de las actividades realizadas por esta figura.</li>
    </ul>
   `,
   subContents: []
});
