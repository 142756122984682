import { Content } from 'src/app/models/content.model';

export const MEDIDA_2_OBJETIVO: Content = new Content({
   idContent: '3685de551ff31bed8f7871aec2d743c0',
   order: 1,
   title: 'Objetivo',
   content: `
    Fomentar que las mujeres, adicionalmente a tener acceso al campo laboral, también estén presentes en todos los niveles directivos del centro de trabajo, toda vez que históricamente existe una notable minoría que ocupa los puestos de toma de decisiones.
   `,
   subContents: []
});
