import { Content } from 'src/app/models/content.model';

export const MEDIDA_2_INTRO: Content = new Content({
   idContent: '550cf757590d99eba26f5e691139ba3d',
   order: 1,
   title: '',
   content: `
      Existencia de un 40% de mujeres en el total de la plantilla de puestos directivos.
   `,
   subContents: []
});
