import { Component } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
  idDoc: string = '';
  public logoInMujeres: string = environment.logos.inMujeres;

  constructor(
    public documentService: DocumentService,
  ) {
    this.idDoc = documentService.docs[0].idDoc;
  }

}
