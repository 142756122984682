import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   Documento probatorio de la existencia de protocolos, códigos, lineamientos, procedimientos o disposiciones de otro tipo, presentados de forma escrita, para prevenir, atender y sancionar actos de violencia laboral y discriminación, entendida en términos de lo que establece la fracción III del artículo 1º de la LFPED. Estos mecanismos deben incluir:
   <br><br>
   <ul>
      <li class="mb-2">
         Acciones para prevenir las prácticas de discriminación y violencia laboral.
      </li>
      <li class="mb-2">
         Estrategias de atención de las mismas, de acuerdo con la solicitud de la víctima y en las que se tenga prevista la atención o canalización de acuerdo con la naturaleza de la violación (administrativa, laboral o penal).
      </li>
      <li class="mb-2">
         Acciones para sancionar estas prácticas en el ámbito administrativo.
      </li>
      <li class="mb-2">
         Información sobre instancias externas y debido proceso en caso de controversia o queja. 
      </li>
   </ul>
   <br><br>
   Prueba de acciones de la difusión de los mecanismos y regulaciones para prevenir, atender y sancionar las prácticas de discriminación y violencia laboral en el centro de trabajo.
   <br><br>
   Prueba de la adopción del compromiso explícito de combatir la violencia laboral en todas sus formas. Puede estar contenida dentro del documento probatorio de la existencia de protocolos, códigos, lineamientos, procedimientos o disposiciones de otro tipo o de la política de igualdad laboral y no discriminación en el centro de trabajo o equivalente.
   <br><br>
   Elementos probatorios de la difusión del mecanismo.
   <br><br>
   Informes que acrediten la ejecución de acciones de prevención de las prácticas de discriminación y violencia laboral, desagregados por sexo.
   <br><br>
   En caso de existir o haber existido denuncias de discriminación o violencia laboral:
   <br><br>
   <ul>
      <li class="mb-2">
         Prueba del número de casos resueltos.
      </li>
      <li class="mb-2">
         Documentos que acrediten la aplicación del procedimiento de sanción administrativa, laboral o penal de prácticas de discriminación y violencia laboral.
      </li>
   </ul>
   <br><br>
   En el caso de los centros de trabajo que no tienen casos de denuncia ante su mecanismo: acta o carta de no existencia de denuncias, firmada por la autoridad competente.

   `,
   subContents: [
   ]
});