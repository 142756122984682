import { Content } from 'src/app/models/content.model';

export const MEDIDA_3_EVIDENCIAS: Content = new Content({
   idContent: '7eade834ee2a2f0c812c1445ae62bf21',
   order: 1,
   title: 'Conformación de evidencias',
   content: `
    El centro de trabajo deberá generar como evidencias:
    <br><br>
    <ul>
      <li>Plantilla de personal donde se muestre la integración de al menos el 5% de personal con alguna discapacidad.</li>
      <li>Documento que compruebe la discapacidad emitido por la autoridad competente, para cada una de las personas empleadas.</li>
      <li>Documento de alta del personal con discapacidad, al régimen obligatorio del Instituto Mexicano del Seguro Social, Instituto de Seguridad Social al Servicio de los Trabajadores o el sistema de seguridad social con el que se cuente en el centro de trabajo.</li>
    </ul>
   `,
   subContents: []
});
