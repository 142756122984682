import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-14/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-14/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-14/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-14/herramientas.data';
import { REQUISITO_NO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/no-critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_14_TOPIC: Topic = new Topic({
   idTopic: '4f73c13bf342fbb2fbeef776b27ae794',
   order: 1,
   title: 'Requisito 14: Mecanismos de regulación para la violencia laboral',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_NO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.second
});