import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Existencia de un código de ética o equivalente.
   <br><br>
   Asegurar, en un plano formal, que los centros de trabajo cuenten con una guía institucional de conducta ética aplicable a todo su personal, en la cual se prohíba todo tipo de discriminación.
   `,
   subContents: [
   ]
});