import { Content } from 'src/app/models/content.model';

export const MEDIDA_4_DESCRIPCION: Content = new Content({
   idContent: '302085f028bf5fb0871a019e3f6ed1d3',
   order: 1,
   title: 'Descripción',
   content: `
    Dentro de un proceso de mediación y atención de quejas por temas relacionados con la discriminación y violencia laboral fundada en prejuicios y estigmas discriminatorios, resulta beneficioso para la resolución de las mismas, contar con una figura con las características de ombudsman, entendiendo a esta como persona encargada de la protección de los derechos de las personas trabajadoras en sus contactos con las autoridades del centro de trabajo, de manera que toda decisión sea pro persona y siempre la que brinde mayor protección y defensa de sus derechos humanos.
    <br><br>
    Dentro del mecanismo para prevenir, atender y sancionar las prácticas de discriminación y violencia laboral fundada en prejuicios y estigmas discriminatorios que se señala en el requisito 14 de esta Norma Mexicana, es necesario que se demuestre la existencia de una figura (hombre o mujer) que cuente con las siguientes características:
    <br><br>
    <ul>
        <li>Unipersonal</li>
        <li>Encargada de la recepción de quejas</li>
        <li>Defensora de los derechos humanos</li>
        <li>Imparcial</li>
        <li>Apolítica</li>
        <li>De participación autónoma</li>
        <li>Que no cobre al personal por los servicios que realiza</li>
        <li>Puede pertenecer o no al centro de trabajo</li>
    </ul>
    La figura mediadora u ombudsman puede pertenecer o no al centro de trabajo. Es recomendable que la persona designada sea observada por la mayor parte de la comunidad del centro de trabajo como confiable y con capacidad de diálogo y negociación transparente y horizontal.
   `,
   subContents: []
});