import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-3/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-3/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-3/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-3/herramientas.data';
import { REQUISITO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_3_TOPIC: Topic = new Topic({
   idTopic: '1b2f7eab2b04f745b85caa590312e524',
   order: 1,
   title: 'Requisito 3: Proceso de reclutamiento y selección',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.third
});