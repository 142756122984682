import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con un proceso de reclutamiento y selección de personal sin discriminación y con igualdad de oportunidades.
   <br><br>
   Garantizar la instrumentación e implementación de un proceso de reclutamiento y selección de personal, que opere bajo el principio de igualdad de trato y de oportunidades entre mujeres y hombres con la ausencia de toda discriminación, en especial por razón de sexo, la derivada de la maternidad y el estado civil o conyugal, con el fin de eliminar las barreras en el acceso al empleo y dirigir los esfuerzos a lograr una igualdad sustantiva en el centro de trabajo. 
   `,
   subContents: [
   ]
});