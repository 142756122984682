import { Component, OnInit, Input } from '@angular/core';

import { Doc } from 'src/app/models/doc.model';

@Component({
  selector: 'app-doc-item',
  templateUrl: './doc-item.component.html',
  styleUrls: ['./doc-item.component.scss']
})

export class DocItemComponent {

  @Input() doc?: Doc;

}
