import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Realizar una auditoría interna. 
   <br><br>
   Verificar el cumplimiento de los requisitos de la Norma Mexicana, mediante una auditoría realizada por el grupo auditor interno, de acuerdo con el alcance de la certificación, para obtener el informe previo a la auditoría de certificación y de vigilancia.
   `,
   subContents: [
   ]
});