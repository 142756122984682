import { Content } from 'src/app/models/content.model';

export const MEDIDA_3_DESCRIPCION: Content = new Content({
   idContent: 'd2b3419ff6b7982c669d8445300b355a',
   order: 1,
   title: 'Descripción',
   content: `
    El centro de trabajo debe demostrar que las y los trabajadores con discapacidad empleadas/os en el mismo, cuentan con una antigüedad de al menos 6 meses, misma que se comprobará con el aviso de alta en el régimen obligatorio del Instituto Mexicano del Seguro Social, Instituto de Seguridad Social al Servicio de los Trabajadores o el sistema de seguridad social con el que se cuente en el centro de trabajo.
    <br><br>
    Se sugiere acercarse al Sistema Integral para el Desarrollo Integral de la Familia, toda vez que es la institución que emite el documento probatorio de alguna discapacidad. 
   `,
   subContents: []
});