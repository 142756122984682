import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá generar las siguientes
   evidencias:
   <br><br>
   <ul>

      <li class="mb-2">
         Manual o documento del proceso de reclutamiento y selección de personal sin discriminación y con igualdad de oportunidades. Este manual debe incluir formatos, formularios o documentación para entrevistas estructuradas y/o exámenes o evaluaciones de ingreso libres de sesgos sexistas o de cualquier tipo de discriminación.
      </li>

      <li class="mb-2">
         Documentos probatorios de anuncios o convocatorias internas y externas publicadas por el centro de trabajo, libres de sesgos sexistas o de cualquier tipo de discriminación. Estos anuncios de vacantes u ofertas de trabajo deben estar expresados con lenguaje incluyente y libre de cualquier tipo de expresión discriminatoria según lo establece la fracción III del artículo 1º de la LFPED, al ser publicitados en los medios que acostumbra el centro de trabajo.
      </li>

      <li class="mb-2">
         Un documento que contenga la declaración explícita de la prohibición de solicitudes de certificados médicos de no embarazo y Virus de Inmunodeficiencia Humana (VIH) como requisitos para el ingreso, permanencia o ascenso en el empleo.
      </li>

      <li class="mb-2">
         Pruebas de la difusión en espacios de comunicación o para las candidatas y los candidatos internas(os) y externas(os).
      </li>

      <li class="mb-2">
         Un catálogo y perfiles de puestos de la estructura organizacional sin discriminación y con igualdad de oportunidades. Debe incluir también un tabulador de salarios que indique los rangos mínimos y máximos para los diferentes niveles de contratación.
      </li>

   </ul>
   `,
   subContents: [
   ]
});