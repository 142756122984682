import { Component, OnInit, Input } from '@angular/core';

import { Content } from 'src/app/models/content.model';

@Component({
  selector: 'app-item-content',
  templateUrl: './item-content.component.html',
  styleUrls: ['./item-content.component.scss']
})
export class ItemContentComponent implements OnInit {

  @Input() content?: Content;
  @Input() sublevel: number = 0;
  @Input() index: number = 0;
  @Input() primaryColor: string = 'black'

  ngOnInit(): void {
    //console.log(this.content);
    
  }
}
