import { Content } from 'src/app/models/content.model';

export const MEDIDA_1_INTRO: Content = new Content({
   idContent: 'aec5e79f80aee4bb7c065c66bbe8ec4c',
   order: 1,
   title: '',
   content: `
      Integración de la plantilla de personal con al menos el 40% de un mismo sexo.
   `,
   subContents: []
});
