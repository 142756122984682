import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Realizar acciones para la corresponsabilidad en la vida laboral, familiar y personal con igualdad de oportunidades.
   <br><br>
   Lograr la corresponsabilidad entre la vida laboral, familiar y personal de quienes se desempeñan en el centro de trabajo, así como promover la transformación de los roles de género en el ejercicio de responsabilidades y quehaceres domésticos y familiares.
   `,
   subContents: [
   ]
});