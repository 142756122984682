import { Topic } from 'src/app/models/topic.model';
import { IGUALDAD_LABORAL } from 'src/app/data/contents/benefits/igualdad-laboral.data'
import { INFOGRAFIA_BENEFICIOS } from './infografia-beneficios.data';

import { environment } from 'src/environments/environment';

export const BENEFITS: Topic = new Topic({
   idTopic: 'f2621b7566549770f77b8d04cfbbf9d3',
   order: 3,
   title: 'Beneficios de la perspectiva de género y la igualdad de oportunidades',
   subTopics: [],
   contents: [
      INFOGRAFIA_BENEFICIOS,
      IGUALDAD_LABORAL
   ],
   primaryColor: environment.colors.second
});
