import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

import { Doc } from 'src/app/models/doc.model';
import { PaginationItem } from 'src/app/models/pagination-item.interface';
import { Topic } from 'src/app/models/topic.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() document?: Doc;
  @Input() currentTopic: string = '';
  @Input() currentSubtopic: string = '';
  @Input() currentDetail: string = '';

  public hasPrevious: boolean = false;
  public hasNext: boolean = false;

  public hasDetails: boolean = false;
  public hasSubTopics: boolean = false;

  public currentTopicIndex: number = -1;
  public currentSubTopicIndex: number = -1;
  public currentDetailIndex: number = -1;

  public nextPage?: PaginationItem;
  public previousPage?: PaginationItem;

  public pages: Array<PaginationItem> = [];
  
  constructor() {

  }
  
  ngOnInit(): void {
    // this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    
    // console.log(changes);
    // console.log({
    //   currentTopic: this.currentTopic,
    //   currentSubtopic: this.currentSubtopic,
    //   currentDetail: this.currentDetail,
    // });

    this.currentTopic = changes['currentTopic'] 
    ? changes['currentTopic'].currentValue
    : this.currentTopic;

    this.currentSubtopic = changes['currentSubtopic'] 
      ? changes['currentSubtopic'].currentValue
      : this.currentSubtopic;

    this.currentDetail = changes['currentDetail'] 
    ? changes['currentDetail'].currentValue
    : this.currentDetail;

    this.hasNext = false;
    this.hasPrevious = false;
    this.previousPage = undefined;
    this.nextPage = undefined;
    // console.log(this.currentTopic);

    this.loadData();
  }

  /*
   ██████╗ ███████╗████████╗██╗███╗   ██╗██████╗ ███████╗██╗  ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██║████╗  ██║██╔══██╗██╔════╝╚██╗██╔╝
  ██║  ███╗█████╗     ██║   ██║██╔██╗ ██║██║  ██║█████╗   ╚███╔╝
  ██║   ██║██╔══╝     ██║   ██║██║╚██╗██║██║  ██║██╔══╝   ██╔██╗
  ╚██████╔╝███████╗   ██║   ██║██║ ╚████║██████╔╝███████╗██╔╝ ██╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝

  */
  /**
   * FUNCTION getIndex
   */
  getIndex(
    topics: Array<Topic>,
    currentIdTopic: string,
  ): number {
    let currentIndex = -1;

    topics.findIndex(
      (topic, index) => {
        if(topic.idTopic == currentIdTopic) {
          currentIndex = index;
        }
      }
    );

    return currentIndex;
  }

  /*
   ██████╗ ███████╗████████╗███╗   ██╗███████╗██╗  ██╗████████╗██╗██████╗ ████████╗ ██████╗ ██████╗ ██╗ ██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝████╗  ██║██╔════╝╚██╗██╔╝╚══██╔══╝██║██╔══██╗╚══██╔══╝██╔═══██╗██╔══██╗██║██╔════╝
  ██║  ███╗█████╗     ██║   ██╔██╗ ██║█████╗   ╚███╔╝    ██║   ██║██║  ██║   ██║   ██║   ██║██████╔╝██║██║
  ██║   ██║██╔══╝     ██║   ██║╚██╗██║██╔══╝   ██╔██╗    ██║   ██║██║  ██║   ██║   ██║   ██║██╔═══╝ ██║██║
  ╚██████╔╝███████╗   ██║   ██║ ╚████║███████╗██╔╝ ██╗   ██║   ██║██████╔╝   ██║   ╚██████╔╝██║     ██║╚██████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚═════╝    ╚═╝    ╚═════╝ ╚═╝     ╚═╝ ╚═════╝

  */
  /**
   * FUNCTION getNextIdTopic
   */
  getNextIdTopic(): string {
    let idToNextTopic = this.document?.topics[this.currentTopicIndex + 1].idTopic!;
    idToNextTopic
    return idToNextTopic;
  }

  /*
   ██████╗ ███████╗████████╗██████╗ ██████╗ ███████╗██╗   ██╗██╗ ██████╗ ██╗   ██╗███████╗██╗██████╗ ████████╗ ██████╗ ██████╗ ██╗ ██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██╔════╝██║   ██║██║██╔═══██╗██║   ██║██╔════╝██║██╔══██╗╚══██╔══╝██╔═══██╗██╔══██╗██║██╔════╝
  ██║  ███╗█████╗     ██║   ██████╔╝██████╔╝█████╗  ██║   ██║██║██║   ██║██║   ██║███████╗██║██║  ██║   ██║   ██║   ██║██████╔╝██║██║
  ██║   ██║██╔══╝     ██║   ██╔═══╝ ██╔══██╗██╔══╝  ╚██╗ ██╔╝██║██║   ██║██║   ██║╚════██║██║██║  ██║   ██║   ██║   ██║██╔═══╝ ██║██║
  ╚██████╔╝███████╗   ██║   ██║     ██║  ██║███████╗ ╚████╔╝ ██║╚██████╔╝╚██████╔╝███████║██║██████╔╝   ██║   ╚██████╔╝██║     ██║╚██████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝     ╚═╝  ╚═╝╚══════╝  ╚═══╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚═╝╚═════╝    ╚═╝    ╚═════╝ ╚═╝     ╚═╝ ╚═════╝

  */
  /**
   * FUNCTION getPreviousIdTopic
   */
  getPreviousIdTopic(): string {
    let idToNextTopic = this.document?.topics[this.currentTopicIndex - 1].idTopic!;
    idToNextTopic
    return idToNextTopic;
  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  /**
   * FUNCTION loadData
   */
  loadData(): void {

    this.document?.topics.forEach((topic, indexTopic) => {


      if(topic.subTopics.length > 0) {

        if(indexTopic > 0) {
          this.pages.push(new PaginationItem({
            url: `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}`,
            title: this.document?.topics[indexTopic].title!,
            subTitle: `Tema ${indexTopic + 1}`
          }));
        }

        this.document?.topics[indexTopic].subTopics.forEach(
          (subTopic, indexSubtopic) => {

            if(subTopic.subTopics.length > 0) {

              this.pages.push(new PaginationItem({
                url: `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}/subTopics/${this.document?.topics[indexTopic].subTopics[indexSubtopic].idTopic}`,
                title: this.document?.topics[indexTopic].subTopics[indexSubtopic].title!,
                subTitle: `Subtema ${indexSubtopic + 1}`
              }));

              this.document?.topics[indexTopic].subTopics[indexSubtopic].subTopics.forEach(
                (detailTopic, indexDetailTopic) => {
                  
                  this.pages.push(new PaginationItem({
                    url: `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}/subTopics/${this.document?.topics[indexTopic].subTopics[indexSubtopic].idTopic}/details/${this.document?.topics[indexTopic].subTopics[indexSubtopic].subTopics[indexDetailTopic].idTopic}`,
                    title: this.document?.topics[indexTopic].subTopics[indexSubtopic].subTopics[indexDetailTopic].title!,
                    subTitle: `Sección ${indexDetailTopic + 1}`
                  }));

                }
              );

            } else {
              this.pages.push(new PaginationItem({
                url: `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}/subTopics/${this.document?.topics[indexTopic].subTopics[indexSubtopic].idTopic}`,
                title: this.document?.topics[indexTopic].subTopics[indexSubtopic].title!,
                subTitle: `Subtema ${indexSubtopic + 1}`
              }));
            }

          }
        );

      }  else {
        this.pages.push(new PaginationItem({
          url: `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}`,
          title: this.document?.topics[indexTopic].title!,
          subTitle: `Tema ${indexTopic + 1}`
        }));
      }

    });

    // console.log(this.pages);

    let currentUrl = '';
    

    this.document?.topics.forEach((topic, indexTopic) => {
      if(topic.idTopic == this.currentTopic) {

        this.currentTopicIndex = indexTopic;

        if(this.currentSubtopic != "") {
          this.document?.topics[indexTopic].subTopics.forEach(
            (subTopic, indexSubtopic) => {

              if(subTopic.idTopic == this.currentSubtopic) {

                this.currentSubTopicIndex = indexSubtopic;
                if(
                  this.currentDetail != ""
                ) {

                  this.document?.topics[indexTopic].subTopics[indexSubtopic].subTopics.forEach(
                    (detailTopic, indexDetailTopic) => {

                      if(this.currentDetail == detailTopic.idTopic) {
                        this.currentDetailIndex = indexDetailTopic;

                        currentUrl = `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}/subTopics/${this.document?.topics[indexTopic].subTopics[indexSubtopic].idTopic}/details/${this.document?.topics[indexTopic].subTopics[indexSubtopic].subTopics[indexDetailTopic].idTopic}`;
                        
                      }

                    }
                  )

                } else {
                  currentUrl = `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}/subTopics/${this.document?.topics[indexTopic].subTopics[indexSubtopic].idTopic}`;
                }

              }

            }
          )
        } else {
          currentUrl = `/docs/${this.document?.idDoc}/topics/${this.document?.topics[indexTopic].idTopic}`;
        }
      }
    });

    // console.log(currentUrl);
    
    let currentIndexPage = this.pages.findIndex(
      (page, indexPage) => page.url == currentUrl
    );

    // console.log(currentIndexPage);

    if(currentIndexPage > 0) {
      this.previousPage = this.pages[currentIndexPage - 1];
    }

    if(currentIndexPage < this.pages.length - 1) {
      this.nextPage = this.pages[currentIndexPage + 1];
    }
    

  }
}
