import { Content } from "src/app/models/content.model";

export const FAQ5: Content = new Content({
    idContent: '53082c6a5885b6b8752db540274f5a80',
    order: 5,
    title: '¿Cuánto cuesta la certificación en la Norma Mexicana?',
    content: `
      Como instituciones públicas, el Conapred, al igual que el Instituto Nacional de las Mujeres
      y la Secretaría del Trabajo y Previsión Social, miembros del Consejo Interinstitucional de
      esta Norma Mexicana, no generan información sobre los costos de las auditorías de certificación o vigilancia, evitando así, prácticas
      que pudieran prestarse a interpretaciones incorrectas y de responsabilidad. Dichos costos son establecidos por los Organismos de
      Certificación que realizan las auditorías a los centros de trabajo y son regulados por las instituciones correspondientes. Por ese motivo,
      se recomienda consultar con todos los Organismos de Certificación a través del directorio proporcionado, a fin de conocer todas las
      ofertas y elegir la que más convenga al centro de trabajo.
    `,
    subContents: []
});
