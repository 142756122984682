import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { DocPageComponent } from './pages/doc-page/doc-page.component';
import { BannerComponent } from './components/banner/banner.component';
import { DocsComponent } from './components/docs/docs.component';
import { DocItemComponent } from './components/doc-item/doc-item.component';
import { UidGeneratorPageComponent } from './pages/uid-generator-page/uid-generator-page.component';
import { TopicsComponent } from './components/topics/topics.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentComponent } from './components/content/content.component';
import { ContentsComponent } from './components/contents/contents.component';
import { TwoNumberCharPipe } from './pipes/two-number-char/two-number-char.pipe';
import { TopicItemComponent } from './components/topic-item/topic-item.component';
import { SanitizedHtmlPipe } from './pipes/sanitized-html/sanitized-html.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ItemContentComponent } from './components/item-content/item-content.component';
import { EditorComponent } from './components/editor/editor.component';
import { AcorditionComponent } from './components/acordition/acordition.component';
import { ButtonUrlComponent } from './components/button-url/button-url.component';
import { ButtonContactComponent } from './components/button-contact/button-contact.component';
import { MenuIndexComponent } from './components/menu-index/menu-index.component';

const componentParsers: Array<HookParserEntry> = [
  {component: ButtonContactComponent},
  {component: ButtonUrlComponent},
  // ...
];

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    DocPageComponent,
    BannerComponent,
    DocsComponent,
    DocItemComponent,
    UidGeneratorPageComponent,
    TopicsComponent,
    ContentComponent,
    ContentsComponent,
    TwoNumberCharPipe,
    TopicItemComponent,
    SanitizedHtmlPipe,
    PaginationComponent,
    ItemContentComponent,
    EditorComponent,
    AcorditionComponent,
    ButtonUrlComponent,
    ButtonContactComponent,
    MenuIndexComponent,
  ],
  entryComponents: [ 
    ButtonContactComponent,
    ButtonUrlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
