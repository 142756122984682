import { Injectable } from '@angular/core';
import { Doc } from 'src/app/models/doc.model';
import { NORM_DOC } from 'src/app/data/docs/norm.data';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  public docs: Array<Doc> = [];

  constructor() { 
    this.docs = [
      NORM_DOC
    ];
  }
  
  async getDocument(idDoc: string): Promise<Doc | undefined> {
    let doc = this.docs.find(doc => doc.idDoc === idDoc);

    return doc;
  }

}
