import { Component, OnInit } from '@angular/core';

import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit{

  constructor(
    public documentService: DocumentService
  ) {}

  ngOnInit(): void {
    
  }

}
