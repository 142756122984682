import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DocumentService } from 'src/app/services/document/document.service';

import { Doc } from 'src/app/models/doc.model';
import { Topic } from 'src/app/models/topic.model';

@Component({
  selector: 'app-doc-page',
  templateUrl: './doc-page.component.html',
  styleUrls: ['./doc-page.component.scss']
})
export class DocPageComponent implements OnInit {

  public document: Doc | undefined;
  public currentTopic: Topic | undefined;

  public idDocument: string = '';
  public idTopic: string = '';
  public idSubTopic: string = '';
  public idDetail: string = '';

  constructor(
    public activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      //console.log(params);

      this.idDocument = params['idDoc'];
      this.idTopic = params['idTopic'] ?? '';
      this.idSubTopic = params['idSubTopic'] ?? '';
      this.idDetail = params['idDetail'] ?? '';

      this.updateView();
      
    });

  }

  async getDocument() {
    this.document = await this.documentService.getDocument(this.idDocument);

    if(this.document) {
      if(this.idTopic == '') {
        this.idTopic = this.document.topics[0].idTopic;
        this.router.navigate([`/docs/${this.idDocument}/topics`, this.idTopic]);
      } else {

        let currentTopic = this.document.topics.find(
          topic => topic.idTopic == this.idTopic
        );

        if(
          currentTopic?.contents.length == 0 &&
          currentTopic?.subTopics.length > 0 &&
          this.idSubTopic == ''
        ) {
          this.idSubTopic = this.document.topics.find(topic => topic.idTopic == this.idTopic)?.subTopics[0].idTopic!;
          this.router.navigate([
            '/docs', this.idDocument, 
            'topics', this.idTopic, 
            'subTopics', this.idSubTopic
          ]);
        } else if(
          currentTopic?.contents.length == 0 &&
          currentTopic?.subTopics.length > 0 &&
          this.idSubTopic != ''
        ) {

          let subTopicCurrent = this.document.topics.find(
            topic => topic.idTopic == this.idTopic
          )?.subTopics.find(
            subTopic => subTopic.idTopic == this.idSubTopic
          );

          if(
            subTopicCurrent?.contents.length! == 0 &&
            subTopicCurrent?.subTopics.length! > 0 &&
            this.idDetail == ''
          ) {

            this.idDetail = this.document.topics.find(
              topic => topic.idTopic == this.idTopic
            )?.subTopics.find(
              subTopic => subTopic.idTopic == this.idSubTopic
            )?.subTopics[0].idTopic!;

            this.router.navigate([
              '/docs', this.idDocument, 
              'topics', this.idTopic, 
              'subTopics', this.idSubTopic,
              'details', this.idDetail
            ]);

          } 
          
          else if (this.idDetail != '') {

            this.currentTopic = this.document.topics.find(
              topic => topic.idTopic == this.idTopic
            )?.subTopics.find(
              subTopic => subTopic.idTopic == this.idSubTopic
            )?.subTopics.find(
              detail => detail.idTopic == this.idDetail
            );

          } else {
            this.currentTopic = this.document.topics.find(
              topic => topic.idTopic == this.idTopic
            )?.subTopics.find(
              subTopic => subTopic.idTopic == this.idSubTopic
            );
          }
        } else {
          this.currentTopic = this.document.topics.find(topic => topic.idTopic == this.idTopic);
        }
      }

    }

    //console.log(this.document);
    
  }

  updateView() { 
    this.getDocument();
  }
}
