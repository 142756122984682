import { Content } from 'src/app/models/content.model';

export const BENEFITS_C: Content = new Content({
   idContent: 'dc9df19c6d78467f11a69a44f8a8a29b',
   order: 1,
   title: 'Atraer y retiene el talento',
   content: `
    Uno de los aspectos relevantes a la hora de gestionar una empresa es lograr que el personal cualificado y preparado permanezca en la empresa y conseguir atraer a nuevos trabajadores. Las empresas tienen en sus manos el desarrollo de condiciones laborales que favorezcan no sólo la permanencia del capital humano con el que ya cuentan sino también la captación de otras nuevas personas trabajadores cualificadas que se vean atraídos por, entre otros motivos, la implementación de buenas prácticas tales como el respeto a su equilibrio de vida laboral, familiar y personal.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});