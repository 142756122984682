import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá generar las siguientes
   evidencias:
   <br><br>
   <ul>
      <li>
         Documentos que acrediten la aplicación del
         cuestionario de percepción de clima laboral
         y no discriminación del personal que conforma
         el centro de trabajo. Pueden ser: un informe
         de resultados, la base de datos con las
         respuestas capturadas o los cuestionarios en
         sí, ya sea en formato impreso o electrónico.
      </li>
   </ul>
   `,
   subContents: [
   ]
});