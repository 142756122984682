import { Content } from 'src/app/models/content.model';

export const STEP_2_CONTENT: Content = new Content({
   idContent: 'a47b9fadf1286ed98b3ef08780188a9b',
   order: 1,
   title: 'Paso 2: Para obtener el folio de participación',
   content: `
   La persona representante del centro de trabajo para el proceso de certificación deberá enviar por correo electrónico los siguientes documentos (Cédula de registro completa y firmada y Carta compromiso en hoja membretada, firmada por la máxima autoridad, alta dirección o representante legal) a los siguientes correos: 
   <br><br>
   
   <div class="row">

      <div class="col-12 mb-4">
         <div class="col-12 col-md-7 col-lg-7 col-xl-5 mx-auto">
            <app-button-contact
               [kindOfContact]="'mailto'"
               [contact]="'igualdadlaboral@stps.gob.mx'">
            </app-button-contact>
         </div>
      </div>

      <div class="col-12 mb-4">
         <div class="col-12 col-md-7 col-lg-8 col-xl-6 mx-auto">
            <app-button-contact
               [kindOfContact]="'mailto'"
               [contact]="'igualdadlaboral@inmujeres.gob.mx'">
            </app-button-contact>
         </div>
      </div>

      <div class="col-12 mb-3">
         <div class="col-12 col-md-7 col-lg-8 col-xl-6 mx-auto">
            <app-button-contact
               [kindOfContact]="'mailto'"
               [contact]="'igualdadlaboral@conapred.org.mx'">
            </app-button-contact>
         </div>
      </div>

   </div>
   `,
   subContents: [
   ]
});