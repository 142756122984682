import { Content } from 'src/app/models/content.model';

export const BENEFITS_D: Content = new Content({
   idContent: '279292b252068006ae14ad65375827b1',
   order: 1,
   title: 'Consolida modelos avanzados de gestión',
   content: `
    El contar con una política de igualdad laboral y no discriminación en las empresas contribuye al acercamiento de éstas a modelos de gestión avanzados que ponen el foco en las personas, en la medida que las políticas de igualdad garantizan un mayor equilibrio entre vida personal, familiar y profesional. Sabido es que las mejores y más innovadoras prácticas empresariales incorporan ya la perspectiva género en su articulación.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});