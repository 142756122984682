import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Es importante considerar el elemento de transparencia inherente a la convocatoria y la difusión de la misma. Como eje fundamental de la Norma, todo proceso que se evalúe en ésta deberá ser del conocimiento generalizado del personal del centro de trabajo. Asimismo, la convocatoria de capacitación deberá estar libre de cualquier elemento discriminatorio de acuerdo a la fracción III del artículo 1º de la Ley Federal para Prevenir y Eliminar la Discriminación.
   <br><br>
   Otro elemento del requisito de evaluación se refiere a tomar en cuenta las características particulares de las personas integrantes de la plantilla de personal, es decir, todas las personas deben tener derecho al acceso a la capacitación que deberá brindarse durante la jornada laboral. No habrán de programarse acciones de capacitación en situaciones que se desfavorezca el acceso de algunas personas a ellas.
   <br><br>
   Las evidencias a presentar como cumplimiento del requisito de certificación deberán contener explícitamente que la capacitación que el centro de trabajo brinda, se imparte sin sesgos sexistas o discriminatorios. Por supuesto, la capacitación del centro de trabajo considerará las necesidades del mismo, acorde a los perfiles de puesto.
   <br><br>
   Se deberá presentar también el registro estadístico de participación y listas de asistencia desagregadas por sexo que den cuenta de las acciones de capacitación del centro de trabajo. Igualmente, las evidencias deberán incorporar documentos, imágenes, fotografías o constancias, que demuestren la participación y la difusión con accesibilidad para todo el personal.

   `,
   subContents: [
   ]
});