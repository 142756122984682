<div class="container-fluid">
   <div class="row">

      <div class="col-12 font-weight-600">
         En esta página
      </div>

      <div class="col-12 mb-1">
         <hr>
      </div>

      <div class="col-12 row text-secondary mb-3"
         *ngFor="let content of contents">
         <div class="col-12 title">
            {{ content.title }}
         </div>
         <div
            *ngIf="content.subContents.length > 0" 
            class="col-12 mt-2">
            <ul class="no-bullets">
               <li
                  *ngFor="let subContent of content.subContents"
                  class="mb-2 title">
                  {{ subContent.title }}
               </li>
            </ul>
         </div>
      </div>

   </div>
</div>