import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Para la realización de esta medición se utilizará la herramienta del Apéndice Normativo E. “Cuestionario de percepción de clima laboral y no discriminación”, el cual se aplicará de manera individual al número de personas determinado mediante una fórmula preestablecida, para obtener la muestra representativa. 
   <br><br>
   El personal auditor del organismo de certificación, dentro de las tareas de la auditoría de certificación debe aplicar el cuestionario de clima laboral. El cálculo del tamaño de muestra puede estar en manos tanto del organismo de certificación como del centro de trabajo, ya que en cualquiera de los casos se usarán las fórmulas que se presentan a continuación.
   <br><br>
   Para el requisito de auditoría interna el cuestionario debe ser aplicado por el centro de trabajo y debe generarse la evidencia correspondiente.
   <br><br>
   La muestra debe ser obtenida con la siguiente fórmula, misma que puede ser proporcionada por el Consejo Interinstitucional en Excel:
   <br><br>
   <ul>
      <li class="mb-2">
         Tamaño de muestra para poblaciones finitas (menores a 100,000):
      </li>
      <ul>
         <li class="mb-2">
            𝑛=(𝑧^2 𝑁𝑝𝑞)/(𝑒^2 (𝑁−1)+𝑧^2 𝑝𝑞 )
         </li>
      </ul>
      <li class="mb-2">
         Tamaño de muestra para poblaciones infinitas (100,000 o más):
      </li>
      <ul>
         <li class="mb-2">
            𝑛=(𝑧^2 𝑝𝑞)/𝑒^2 
         </li>
      </ul>
      <li>
         Donde
      </li>
      <ul>
         <li>
            n=tamaño de muestra
         </li>
         <li>
            z=nivel de confianza
         </li>
         <li>
            p=probabilidad a favor
         </li>
         <li>
            q=probabilidad en contra
         </li>
         <li>
            e=error
         </li>
      </ul>
   </ul>
   <br><br>
   Tamaño de muestra para poblaciones infinitas
   (100,000 o más):
   <br<br>
   Donde:
   <ul class="no-bullets">
      <li>n = tamaño de muestra</li>
      <li>z = nivel de confianza</li>
      <li>p = probabilidad a favor</li>
      <li>q = probabilidad en contra</li>
      <li>e = error</li>
   </ul>
   <br><br>
   Para la aplicación del cuestionario, el centro
   de trabajo deberá utilizar un error de 5%, un
   nivel de confianza de 95% (valor de z=1.96) y
   valores de p y q de 0.5 (en caso de que se desconozcan).
   <br><br>
   Cuando el centro de trabajo cuente con personal
   con alguna discapacidad, el organismo de
   certificación deberá considerar este cuestionario
   en formatos y medios accesibles, macrotipos,
   braille, entre otros.
   <br><br>
   El cuestionario puede ser aplicado en formato
   impreso o electrónico. La norma no especifica
   esto, por ello es recomendable que el
   centro de trabajo lo acuerde previamente
   con el organismo de certificación, de acuerdo
   con los medios que faciliten que las y los
   trabajadores respondan la encuesta dentro
   de su horario laboral.
   <br><br>
   La norma señala que para la obtención del
   puntaje de este requisito se tomará en cuenta
   solamente la aplicación del cuestionario, mas
   no los resultados del mismo, y pide documentos
   como evidencia, sin especificar cuáles. Sin
   embargo, se recomienda generar un informe
   de resultados que brinde al centro de trabajo
   insumos para conocer la percepción del personal
   y tomar acciones que promuevan la igualdad
   laboral y la no discriminación en los temas
   específicos detectados en el cuestionario como
   áreas de oportunidad.
   `,
   subContents: [
   ]
});