import { Content } from 'src/app/models/content.model';

export const MEDIDA_3_OBJETIVO: Content = new Content({
   idContent: '9ace447b5c084c3a2759942cb20770a5',
   order: 1,
   title: 'Objetivo',
   content: `
    Propiciar que uno de los grupos mayormente discriminados en el ámbito laboral como son las personas con discapacidad, tenga un mayor acceso al empleo digno y formal, contribuyendo así al empoderamiento y vida independiente de estas personas.
   `,
   subContents: []
});
