import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Para el cumplimiento del requisito de certificación deberá contemplarse el desarrollo de una campaña de sensibilización en materia de igualdad laboral y no discriminación. Dicha campaña deberá estar dirigida a todo el personal del centro de trabajo.
   <br><br>
   El centro de trabajo deberá considerar una capacitación específica en los temas de la Norma Mexicana, dirigida al personal del área de recursos humanos y del grupo, comisión o comité para la igualdad laboral y no discriminación.
   <br><br>
   Deberá demostrarse que se cuenta con un programa de capacitación en los temas de la Norma Mexicana, que se llevó a cabo una campaña para el conocimiento de dicha capacitación para todo el personal y mostrar también todos los documentos probatorios.
   <br><br>
   Así, el centro de trabajo deberá presentar documento que contenga el plan de capacitación y sensibilización en igualdad laboral y no discriminación, el reconocimiento y respeto a la diversidad. El documento solicitado deberá estar vigente o haberse desarrollado en el último año anterior a la certificación. 
   <br><br>
   También se deberá presentar el registro estadístico de participación y listas de asistencia desagregadas por sexo, con el objeto de mostrar qué personas han sido beneficiadas con la capacitación solicitada. Finalmente se podrán presentar documentos, imágenes, fotografías o constancias, que demuestren la participación y la difusión con accesibilidad para todo el personal.

   `,
   subContents: [
   ]
});