import { Content } from "src/app/models/content.model";

export const FAQ4: Content = new Content({
    idContent: '68e236e4437d89b766091d9301f3439e',
    order: 4,
    title: '¿Qué es un esquema de certifiación multisitio?',
    content: `
        De acuerdo con la propia Norma Mexicana, es aquel centro de trabajo que tenga una central
        de funcionamiento identificada (a la que se hace referencia normalmente “Oficina Central”
        o “Corporativo”) en la que se planifican, controlan y dirigen ciertas actividades y una red de
        “Oficinas Locales” o “Sucursales” o “Instalaciones” en las que dichas actividades se llevan a
        cabo total o parcialmente. El centro de trabajo con multisitios no tiene que ser una entidad
        única, pero todas “Oficinas Locales” o “Sucursales” o “Instalaciones” deberán tener un enlace
        legal o contractual con la Oficina Central y estar sujetas a una Cadena de Custodia común que,
        a su vez, estará sujeta al control de la Oficina Central. Esto significa que la Oficina Central
        tiene derecho a aplicar medidas correctivas en cualquier instalación cuando sean necesarias.
        Cuando sea aplicable, esto deberá constatarse en el contrato entre la Oficina Central y el resto
        de las instalaciones.
    `,
    subContents: []
});
