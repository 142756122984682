import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá generar las siguientes
   evidencias:
   <br><br>
   <ul>
      <li class="mb-2">
         Un documento que contenga la política de
         igualdad laboral y no discriminación:
         <ul>
            <li>
               Armonizada con lo que establece la fracción
               III del artículo 1º de la LFPED.
            </li>
            <li>
               Aprobada y firmada por la alta dirección
               del centro de trabajo
            </li>
            <li>
               Que describa explícitamente el compromiso
               de la alta dirección con el respecto a la
               igualdad laboral y no discriminación
            </li>
            <li>
               Que describa explícitamente el compromiso
               de la alta dirección con todo el personal
               contemplado en el alcance sin importar
               el tipo de contratación.
            </li>
            <li>
               Que incluya en su contenido la prohibición
               explícita del maltrato, violencia y segregación
               por parte de las autoridades del
               centro de trabajo
            </li>
         </ul>
      </li>
      <li class="mb-2">
         Pruebas de la difusión de la política de igualdad
         laboral y no discriminación, en forma accesible,
         a todo el personal contemplado en el
         alcance.
      </li>
      <li class="mb-2">
         Un documento de la designación de la persona
         responsable de la implementación de
         la política y de los objetivos. 
      </li>
      <li class="mb-2">
         Documentos aprobados por la alta dirección,
         que contengan los planes de acción para el
         logro de los objetivos de su política en igualdad
         laboral y no discriminación. Los planes
         de acción que se muestren como evidencia
         deberán estar diseñados bajo los principios
         del ciclo planear-hacer-verificar-actuar, y se
         debe demostrar de manera incontrovertible
         que las acciones que se presentan en éstos
         provienen de cada una de esas cuatro etapas.
         Es menester considerar:
         <ul>
            <li>
               Los resultados obtenidos de la aplicación
               del Apéndice C (Diagnóstico de autoevaluación).
            </li>
            <li>
               Todos los elementos que se establecen
               en la política.
            </li>
            <li>
               Acciones correctivas para solucionar las
               no conformidades o incumplimientos de
               los requisitos.
            </li>
         </ul>
      </li>
   </ul>
   `,
   subContents: [
   ]
});