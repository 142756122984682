import { Topic } from 'src/app/models/topic.model';

import { PASOS_CERTIFICACION_TOPIC } from 'src/app/data/topics/como-puedo-certificar/pasos-certificacion.data';
import { REQUISITOS_PARTICIPACION_TOPIC } from './requisitos-participacion.data';
import { REQUISITOS_CERTIFICACION_TOPIC } from './requisitos-certificacion/requisitos-certificacion.data';
import { MEDIDAS_NIVELACION } from '../medidas-de-nivelacion/medidas.data';
import { AUDITORIA_DE_VIGILANCIA } from '../medidas-de-nivelacion/auditoria.data';
import { PADRON_NACIONAL } from '../padron-nacional/padron.data';
import { ORGANISMOS_CERTIFICACION } from '../organismos-certificacion/organismos.data';
import { environment } from 'src/environments/environment';

export const COMO_PUEDO_CERTIFICAR_TOPIC: Topic = new Topic({
   idTopic: '7fdde4b249e9616fe9ea39d2f5267cf9',
   order: 1,
   title: '¿Cómo puedo certificar a mi centro de trabajo en la Norma Mexicana?',
   subTopics: [
      PASOS_CERTIFICACION_TOPIC,
      REQUISITOS_PARTICIPACION_TOPIC,
      REQUISITOS_CERTIFICACION_TOPIC,
      MEDIDAS_NIVELACION,
      AUDITORIA_DE_VIGILANCIA,
      PADRON_NACIONAL,
      ORGANISMOS_CERTIFICACION
   ],
   contents: [
   ],
   primaryColor: environment.colors.fourth
});
