import { Topic } from 'src/app/models/topic.model';

import { STEP_1_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-participacion/paso-1.data';
import { STEP_2_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-participacion/paso-2.data';
import { STEP_3_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-participacion/paso-3.data';
import { REQUISITOS_DESCRIPCION } from './requisitos-certificacion/requisitos-descripcion.data';
import { INFOGRAFIA_REQUISITOS_PARTICIPACION } from './infografia-requisitos-participacion.data';
import { environment } from 'src/environments/environment';

export const REQUISITOS_PARTICIPACION_TOPIC: Topic = new Topic({
   idTopic: '7587a20fbe3a51cb8abecdefb65f6c4b',
   order: 1,
   title: 'Requisitos de participación administrativos',
   subTopics: [],
   contents: [
      INFOGRAFIA_REQUISITOS_PARTICIPACION,
      // REQUISITOS_DESCRIPCION,
      STEP_1_CONTENT,
      STEP_2_CONTENT,
      STEP_3_CONTENT,
   ],
   primaryColor: environment.colors.fifth
});
