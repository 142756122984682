import { Content } from 'src/app/models/content.model';

export const BENEFITS_B: Content = new Content({
   idContent: 'fd25b7213ad12027d1e0b5e3d7729a12',
   order: 1,
   title: 'Consolida la responsabilidad social empresarial.',
   content: `
    Este es un elemento clave para el desarrollo de la dimensión interna de la responsabilidad social corporativa, relacionada esta, entre otros, con la gestión de los recursos humanos y con la mejora de la percepción que sus partes interesadas y la sociedad, en general, tienen de la empresa por su contribución al cambio social.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});