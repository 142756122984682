import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   Ejemplo de criterios para el proceso de reclutamiento y selección libre de discriminación y que garantice la igualdad de oportunidades.
   <br><br>
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55137/3.1_Ejemplo_de_criterios_para_el_proceso_de_reclutamiento_y_selecci_n.pdf'"
               [title]="'Ejemplo'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   <br>
   Esquema de los criterios para el reclutamiento y selección libre de discriminación y que garantice la igualdad de oportunidades.
   <br><br>
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55285/3.2_Esquema_de_proceso_de_reclutamiento_y_selecci_n.pdf'"
               [title]="'Esquema'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>

   `,
   subContents: [
   ]
});