import { Content } from 'src/app/models/content.model';

export const BENEFITS_E: Content = new Content({
   idContent: '609859bf52b8418b4d3e034b4feb594d',
   order: 1,
   title: 'Mejora el clima laboral y con ello aumento de los beneficios de la empresa',
   content: `
   Al incorporar medidas de igualdad laboral y no discriminación, se consigue una mejora significativa del clima laboral y un mayor compromiso de las personas con la organización. Esto lleva asociados aspectos relacionados con la mejora de la productividad y los beneficios económicos:
   <br><br>
   <ul>
    <li>En la medida en que la igualdad de oportunidades se convierte en un proyecto participativo común en el que intervienen todas las áreas de la organización se consigue aumentar la motivación del equipo.</li>
    <li>Personal que comparte en mayor medida la cultura, los valores y los objetivos empresariales aumentando con ello su compromiso.</li>
    <li>Menor ausentismo laboral ligado principalmente a la existencia de medidas de conciliación. Esto conlleva necesariamente una reducción de costes.</li>
    <li>Menor índice de rotación voluntaria. Unas condiciones laborales adecuadas e igualitarias provocan un menor índice de renuncias a la vida laboral y con ello una menor rotación de la plantilla del centro de trabajo.</li>
   </ul>
   `,
   subContents: [],
   kindOfContent: 'subsection'
});