import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   <ul>
   
      <li class="mb-2">
         Pruebas de que existe una campaña de sensibilización en materia de igualdad laboral y no discriminación en el centro de trabajo.
      </li>

      <li class="mb-2">
         Pruebas de acciones de sensibilización, difusión y promoción, en materia de reconocimiento y respeto a la diversidad.
      </li>

      <li class="mb-2">
         Documento que contenga el plan o programa de capacitación y sensibilización en materia de igualdad laboral, no discriminación, derechos humanos, perspectiva de género y reconocimiento y respeto a la diversidad, que cuente con un apartado específico para el personal del área de recursos humanos y para el grupo, comisión o comité para la igualdad laboral y no discriminación.
      </li>

      <li class="mb-2">
         Cartas descriptivas o planes de sesión de los cursos, talleres o conferencias impartidos, los cuales pueden ser presenciales, semipresenciales, virtuales o a distancia, para facilitar la inclusión de las y los trabajadores, dentro de su horario laboral.
      </li>

      <li class="mb-2">
         Registro estadístico de participación, con información desagregada por sexo.
      </li>

      <li class="mb-2">
         Listas de asistencia desagregadas por sexo.
      </li>

      <li class="mb-2">
         Documentos, imágenes, fotografías o constancias que demuestren la participación del personal.
      </li>

      <li class="mb-2">
         Documentos, imágenes, fotografías o constancias que demuestren la difusión con accesibilidad para todo el personal.
      </li>
   
   </ul>
   `,
   subContents: [
   ]
});