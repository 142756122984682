import { Content } from "src/app/models/content.model";

export const FAQ6: Content = new Content({
    idContent: '3fb5bbb404fd4c5f32252a2f038f5ad8',
    order: 6,
    title: '¿Cuánto tiempo lleva certificarse en la Norma Mexicana?',
    content: `
      El tiempo que dure el proceso de certificación depende por completo del centro de trabajo,
      de las evidencias documentales con las que cuentan para el cumplimiento de los requisitos y con sus áreas de oportunidad, así como
      el envío de documentos necesarios para obtener el folio de registro y de la obtención de constancias de no incumplimiento a la Ley
      Federal del Trabajo y a la Ley Federal para Prevenir y Eliminar la Discriminación En este sentido, reiteramos la importancia de
      realizar el autodiagnóstico que se encuentra en el Apéndice Normativo C de la Norma Mexicana y solicitar asesoría puntual al Consejo Interinstitucional.
    `,
    subContents: []
});
