import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   <ul>
      
      <li class="mb-2">
         Manuales o documentos de operación o procedimientos de ascenso y permanencia que describan procesos transparentes y accesibles de movilidad horizontal y vertical libres de sesgos sexistas o discriminatorios.
      </li>

      <li class="mb-2">
         Documento de la convocatoria previa para la participación el mecanismo de evaluación del desempeño.
      </li>

      <li class="mb-2">
         Documento con el resultado del mecanismo de evaluación del desempeño del personal, que demuestre la objetividad y que contenga información desagregada por sexo.
      </li>

      <li class="mb-2">
         Pruebas de la difusión con accesibilidad para todo el personal de forma clara y oportuna.
      </li>

   </ul>
   `,
   subContents: [
   ]
});