import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Se tomará como documento probatorio del cumplimiento de este requisito a todo documento que reúna el conjunto de directrices que tengan por objeto establecer las conductas aceptables para las personas integrantes de un centro de trabajo y que promuevan una cultura de igualdad laboral y no discriminación.
   <br><br>
   Para el caso de las instituciones de la administración pública, de cualquier nivel, podrían considerarse válidos los códigos de conducta que se emiten de acuerdo con las normatividades que les apliquen, sólo si se cumple con los elementos y evidencias que solicita el requisito.
   <br><br>
   La norma pide que el código de ética presentado como evidencia integre las sanciones que correspondan a su incumplimiento. Se considerará que se cumple con el requisito cuando las sanciones se contemplen en otro documento, se agregue una referencia explícita en el código de ética y se presente el documento donde se hallan las sanciones.
   `,
   subContents: [
   ]
});