import { Content } from "src/app/models/content.model";

export const FAQ15: Content = new Content({
    idContent: '90aa3ae28f2040ae5a1bc9e7b0f55d2f',
    order: 15,
    title: '¿En la auditoría de vigilancia, se realizará nuevamente el cuestionario de clima laboral y no discriminación?',
    content: `
        Sí, ya que forma parte de uno de los requisitos críticos de la Norma Mexicana, sin embargo,
        al tratarse de una vigilancia, se reducirá el nivel de confianza al 50% en la aplicación del
        cuestionario de clima laboral y no discriminación a realizarse por el Organismo de Certificación, con el objetivo de disminuir el tamaño
        de la muestra.
    `,
    subContents: []
});
