import { Content } from 'src/app/models/content.model';

export const BENEFITS_F: Content = new Content({
   idContent: 'b116539def3bbe8be75286c623108908',
   order: 1,
   title: 'Permite la gestión de riesgos relacionados con la desigualdad de oportunidades y la discriminación',
   content: `
   Una gestión de riesgos con perspectiva de género permitirá a las empresas preservar o mantener los activos materiales, personales e inmateriales, en la posición óptima para la consecución de sus objetivos. En este sentido, permite tener la preparación necesaria ante eventualidades relacionadas con denuncias de discriminación o desigualdad.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});