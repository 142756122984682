<footer class="footer text-white">
    <div class="container-fluid">
        <div class="row px-3 py-4">
            <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0 text-center">
                <img 
                    [src]="logoInMujeres" 
                    class="img-footer">
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0 ">
                <h4 class="mb-2">
                    Enlaces
                </h4>
                <ul class="no-bullets p-0 m-0" *ngFor="let doc of documentService.docs[0].docfiles">
                    <li class="mb-1">
                        <a 
                            href="{{doc.url}}" 
                            class="text-decoration-none text-white font-weight-200 text-content">
                            {{doc.name}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-4 mb-lg-0">
                <h4>¿Qué es Inmujeres?</h4>
                <p class="font-weight-200 text-content">
                    Es un portal que pérmite conocer sobre los requisitos y pasos para obtener la certificación NMX-R-025-SCFI-2015 en igualdar laboral y la no discriminación.
                </p>
                <h4>Temas</h4>
                <ul class="no-bullets p-0 m-0" *ngFor="let topic of documentService.docs[0].topics">
                    <li class="mb-1">
                        <a 
                            [routerLink]="['/docs', idDoc, 'topics', topic.idTopic]"
                            *ngIf="topic"
                            class="text-decoration-none text-white font-weight-200 text-content">
                            {{topic.title}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
                <h4>Contacto</h4>
                <p class="font-weight-200 text-content">Dudas e información a contacto@inmujeres.gob.mx</p>
                <h4>Siguenos en</h4>
                <ul class="nav flex-column no-bullets">
                    <li class="nav-item mb-2">
                        <div class="col-6">
                            <a href="https://www.facebook.com/gobmexico" class="text-decoration-none text-white">
                                <img src="../../../assets/social-network/facebook.png" 
                                    alt="MDN"
                                    width="32"
                                    height="32">
                            </a>
                            <a href="https://twitter.com/GobiernoMX" class="text-decoration-none text-white">
                                <img src="../../../assets/social-network/gorjeo.png" 
                                    alt="MDN"
                                    width="32"
                                    height="32">
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
    </div>
    <div class="section-final-footer">
    </div>
</footer>