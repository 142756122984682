export interface IDocFile{
    idDocFile: string;
    order: number;
    name: string;
    url: string;
}

export class DocFile{
    idDocFile: string;
    order: number;
    name: string;
    url: string;

    constructor(data: IDocFile){
        this.idDocFile = data.idDocFile;
        this.order = data.order;
        this.name = data.name;
        this.url = data.url;
    }
}