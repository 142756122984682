import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con un plan de capacitación y sensibilización en igualdad laboral y no discriminación para el personal del centro de trabajo.
   <br><br>
   Capacitar y sensibilizar al personal del centro de trabajo sobre igualdad laboral y no discriminación, así como lograr la especialización del área de recursos humanos del grupo, comisión o comité para la igualdad laboral y no discriminación en estos temas.
   `,
   subContents: [
   ]
});