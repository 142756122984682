import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con un grupo, comisión o comité encargado de la vigilancia del desarrollo e implementación de prácticas de igualdad laboral y no discriminación en el centro de trabajo.
   <br><br>
   Desarrollar, implementar y evaluar las prácticas derivadas de la política de igualdad laboral y no discriminación por medio de un grupo de hombres y mujeres representados equitativamente respecto a la población total del centro del centro de trabajo y provenientes de diversas áreas de responsabilidad, manteniendo un espacio informado, de seguimiento y de toma de decisiones en materia de igualdad laboral y no discriminación, para el cumplimiento de la Norma Mexicana.
   `,
   subContents: [
   ]
});