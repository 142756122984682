import { Content } from 'src/app/models/content.model';

export const STEP_1_CONTENT: Content = new Content({
   idContent: 'ce13d404e4dcb0c786654b32013cbe2f',
   order: 1,
   title: 'Paso 1: Llenar los documentos (cédula de registro y carta compromiso)',
   content: `
   El centro de trabajo deberá registrar su participación en el proceso de certificación de la norma ante el Consejo Interinstitucional de la Norma Mexicana, integrado por la Secretaría del Trabajo y Previsión Social (STPS), el Instituto Nacional de las Mujeres (Inmujeres) y el Consejo Nacional para Prevenir la Discriminación (Conapred). 
   <br><br>
   <div class="row d-flex justify-content-between">
      <div class="col-5">
         <app-button-url
            [url]="'https://www.gob.mx/cms/uploads/attachment/file/56910/Cedula_de_registro_para_la_certificacion_de_la_Norma_Mexicana_en_Igualdad_Laboral_y_No_Discriminacion.pdf'"
            [title]="'Cédula de registro'"
            [icon]="'description'">
         </app-button-url>
      </div>

      <div class="col-5">
         <app-button-url
            [url]="'https://www.gob.mx/cms/uploads/attachment/file/112323/Carta_Compromiso.pdf'"
            [title]="'Carta Compromiso'"
            [icon]="'description'">
         </app-button-url>
      </div>

   </div>
   
   <br>
   La carta compromiso y cédula de registro deberán estar en hoja membretada, firmada por la máxima autoridad, alta dirección o representante legal.
   
   `,
   subContents: [
   ]
});