import { Topic } from 'src/app/models/topic.model';
import { PADRON_LISTADO } from './padron-listado.data';
import { PADRON_CONTENT } from './padron-content.data';

export const ORGANISMOS_CERTIFICACION: Topic = new Topic({
   idTopic: '45171d680780e1b95b56f5780ba53866',
   order: 1,
   title: 'Organismos de certificacion acreditados',
   contents: [
    PADRON_CONTENT,
    PADRON_LISTADO
   ],
   subTopics: []
});
