import { Content } from 'src/app/models/content.model';

export const PADRON_DESCRIPCION: Content = new Content({
   idContent: '1a80dca2e02f0040b31d8e5c3f31e525',
   order: 1,
   title: 'Descripción',
   content: `
    Es la relación de los centros de trabajo certificados a nivel nacional con información relevante y las características de su certificación. Asimismo, es el único registro respaldado por el Consejo Interinstitucional de la Norma Mexicana para dar fe de los centros de trabajo con certificado vigente.
   `,
   subContents: []
});