import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   En este primer requisito crítico se debe cumplir con todos los elementos y presentar todas las evidencias. La política de igualdad laboral y no discriminación en el centro de trabajo (o con nombre equivalente), debe de encontrarse expresada en algún documento institucional (manual de organización, reglamento interno, entre otros); es decir, el documento para evidenciar que cuenta con una política es libre, siempre y cuando incorpore todos los elementos referidos. Pudiera tomarse como base la herramienta anexa de apoyo que proporciona el Consejo Interinstitucional.
   <br><br>
   La política debe especificar la prohibición explícita del maltrato, violencia y segregación por parte de las autoridades del centro de trabajo hacia el personal y también entre el personal, de acuerdo con los motivos de discriminación que la Norma Mexicana enlista, así como mencionar de forma evidente que se encuentra armonizada con la fracción III del artículo 1º de la Ley Federal para Prevenir y Eliminar la Discriminación (LFPED). 
   <br><br>
   A su vez, se debe mostrar de forma expresa el compromiso asumido con todo el personal involucrado en el alcance de la certificación. Esto implica proporcionar evidencia de que las características y los efectos de la certificación han sido difundidos entre dicho personal, por el medio de su elección, el cual debe ser accesible para todos y todas. 
   <br><br>
   La política debe ser firmada por la máxima autoridad, alta dirección o representante legal de acuerdo con el alcance determinado por el centro de trabajo. El compromiso de su implementación y cumplimiento de objetivos puede estar a cargo de la misma persona que firma o de quien el centro de trabajo designe, siempre y cuando lo haga de forma escrita, como parte de las evidencias.
   <br><br>
   Respecto a los planes de acción para el logro de los objetivos de la política en igualdad laboral y no discriminación, la norma pide planes, en plural, de modo que debe haber más de uno. Si bien la norma solicita incluir en dichos planes las acciones correctivas para solucionar las no conformidades o incumplimientos respecto de los requisitos encontrados en la aplicación del Apéndice C. Diagnóstico de autoevaluación, se recomienda incluir asimismo las no conformidades o incumplimientos de los requisitos que se encuentren durante la auditoría interna.
   <br><br>
   Toda documentación presentada como evidencia deberá ser difundida por medios accesibles y adecuados, con lenguaje incluyente y no sexista, para hacerla de conocimiento de todo el personal; además, deberá tomarse en cuenta en todo momento la accesibilidad tecnológica para las personas con discapacidad, tal y como se establece en los términos y definiciones de la Norma Mexicana.
   `,
   subContents: [
   ]
});