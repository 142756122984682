import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-contact',
  templateUrl: './button-contact.component.html',
  styleUrls: ['./button-contact.component.scss']
})
export class ButtonContactComponent implements OnInit {

  @Input() kindOfContact: string = 'mailto';
  @Input() contact: string = '';

  ngOnInit(): void {
    
  }

}
