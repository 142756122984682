import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   Ejemplo de procedimiento para atención de quejas contra hostigamiento y acoso sexual y laboral y conductas de discriminación
   <br><br>
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55299/14._Ejemplo_de_mecanismo_de_atenci_n_a_las_pr_cticas_de_discriminaci_n_V.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   `,
   subContents: [
   ]
});