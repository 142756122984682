import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá presentar documentación que compruebe la implementación y difusión de un código de ética o documento equivalente, por lo que tendrá que generar como evidencia:
   <br><br>
   <ul>
      <li class="mb-2">
         Un código de ética o equivalente, en el cual sea explícita la prohibición de todo tipo de discriminación hacia y entre el personal del centro de trabajo, en el cual se establezcan sanciones explícitas en caso de discriminación, o se refiera a otro documento que las contenga, y se encuentre armonizado con lo establecido en la política de igualdad laboral y no discriminación.
      </li>
      <li class="mb-2">
         Pruebas de la difusión del código de ética o equivalente entre el personal del centro de trabajo.
      </li>
   </ul>
   `,
   subContents: [
   ]
});