import { Content } from "src/app/models/content.model";

export const FAQ10: Content = new Content({
    idContent: 'df92c8b9d7265f6bc95431ac92dd30e2',
    order: 10,
    title: 'Para la auditoría de vigilancia ¿se necesitan volver a pedir las constancias de no incumplimiento a la Ley Federal del Trabajo y a la Ley Federal para Prevenir y Eliminar la Discriminación respectivamente?',
    content: `
      No, estas constancias ya no se requieren.
    `,
    subContents: []
});
