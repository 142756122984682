import { Content } from 'src/app/models/content.model';

export const BENEFITS_G: Content = new Content({
   idContent: '38c7d662ecc05f744b6f36e8fa15894e',
   order: 1,
   title: 'Accede a nuevas cadenas de valor',
   content: `
   Analistas internacionales en sostenibilidad -como por ejemplo el Dow Jones Sustainability Index -ya incluyen la igualdad entre los criterios de valoración de las compañías cotizadas. Contar con políticas de igualdad de oportunidades de mujeres y hombres y evidenciar su implantación positiva en el tiempo constituye uno de los criterios a valorar e impacta directamente en la calificación final de centros de trabajo en todo el mundo.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});