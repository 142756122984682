import { Content } from 'src/app/models/content.model';

export const AUDITORIA_DESCRIPCION: Content = new Content({
   idContent: '6d6ea08db97d5de2a282e7d36a182982',
   order: 1,
   title: 'Descripción',
   content: `
    De acuerdo con lo establecido en el proceso de certificación, todos los centros de trabajo de ben demostrar el mantenimiento de las con
    diciones que propiciaron su certificación, por lo que se realizará a los dos años de vigencia una auditoría de vigilancia por el Organismo de
    Certificación. Para la auditoría de vigilancia se llevará a cabo la evaluación de la conformidad de los requisitos de certificación, por lo que se
    programará la ejecución de la misma antes de que se cumpla la vigencia a partir de la fecha en que se otorgó el certificado.
    <br><br>
    En la auditoría de vigilancia el centro de trabajo deberá demostrar el cumplimiento del 100% de los puntos críticos y solventar como mínimo
    un 70% las áreas de oportunidad detectadas en la auditoría de certificación, así como aplicar el cuestionario de clima laboral, en caso de no
    cumplir, se otorgará periodo de 60 días hábiles para solventar los requisitos necesarios que le permitan al centro de trabajo dar cumplimiento;
    durante este periodo su certificado será suspendido. En caso de no dar cumplimiento al tiempo establecido, su certificado será cancelado.
    El centro de trabajo podrá solicitar la certificación nuevamente cuando así lo considere.
    <br><br>
    En el caso de que un centro de trabajo no acepte aplicar la auditoría de vigilancia, el organismo de certificación encargado comunicará la
    suspensión de la certificación al centro de trabajo y al consejo interinstitucional, otorgando
    un plazo de sesenta días naturales a partir de este momento, para realizar la auditoría de vi gilancia o en su defecto, proceder a la cancelación del certificado.
   `,
   subContents: []
});
