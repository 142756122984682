import { IContent, Content } from "src/app/models/content.model";

export interface ITopic {
   idTopic: string;
   order: number;
   title: string;
   subTopics: Array<ITopic>;
   contents: Array<IContent>;
   showHomePage?: boolean;
   primaryColor?: string;
}

export class Topic {
   idTopic: string;
   order: number;
   title: string;
   subTopics: Array<Topic>;
   contents: Array<Content>;
   showHomePage: boolean = true;
   primaryColor: string;

   constructor(data: ITopic) {
      this.idTopic = data.idTopic;
      this.order = data.order;
      this.title = data.title;
      this.subTopics = data.subTopics
         ? data.subTopics.map(subTopic => new Topic(subTopic))
         : [];
      this.contents = data.contents
         ? data.contents.map(content => new Content(content))
         : [];
      this.primaryColor = data.primaryColor || 'black';

      if(data.showHomePage !== undefined) {
         this.showHomePage = data.showHomePage;
      }
   }
}
