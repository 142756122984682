import { Content } from 'src/app/models/content.model';

export const BENEFITS_A: Content = new Content({
   idContent: '9995ee0e134fee5d42fc3880d6b4567f',
   order: 1,
   title: 'Cumple la legislación vigente',
   content: `
    La implementación de procedimientos y prácticas a favor de la igualdad de oportunidades y no discriminación redunda en poner en acción los preceptos contenidos en la normatividad vinculante para los centros de trabajo.
    <br>
    La Ley Federal del Trabajo contiene la obligación patronal de crear y mantener condiciones necesarias a efecto de contar con igualdad sustantiva en los centros de trabajo. Por tanto, la incorporación de la igualdad laboral y no discriminación permite que cumplan con la legislación en vigor que las obliga.
    <br><br>
    Así mismo, La Ley General de Acceso de las Mujeres
   a una Vida Libre de Violencia contiene en
   el Capítulo II, artículos 10,11, 13 y 15 la definición
   de la violencia laboral así como la obligación de
   establecer mecanismos que favorezcan la erradicación
   de hostigamiento y acoso sexual.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});