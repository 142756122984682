import { Content } from 'src/app/models/content.model';


export const REQUISITO_PARTICIPACION_CONTENT: Content = new Content({
   idContent: '2104453b2d4180d49efb40bb51fe79c6',
   order: 1,
   title: 'Requisitos de participación o administrativos',
   content: `
      <ul>
         <li>Cédula de registro y carta compromiso</li>
         <li>Folio</li>
         <li>Constancia de no incumplimiento</li>
      </ul>
   `,
   subContents: [
      
   ]
});