import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-1/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-1/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-1/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-1/herramientas.data';
import { REQUISITO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_1_TOPIC: Topic = new Topic({
   idTopic: '6adb0813a73bf51d8a6ea15abd9f3d00',
   order: 1,
   title: 'Requisito 1: Política de igualdad laboral y no discriminación',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.first
});