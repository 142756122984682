import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   <ul>
   
      <li class="mb-2">
         Fotografías de la existencia de un espacio exclusivo, privado, adecuado e higiénico para la lactancia o extracción de leche.
      </li>

      <li class="mb-2">
         Que se realice una verificación física del espacio exclusivo, privado, adecuado e higiénico para la lactancia o extracción de leche en las auditorías interna, de certificación y vigilancia.
      </li>

      <li class="mb-2">
         Prueba de la promoción de la lactancia materna en el centro de trabajo.
      </li>

      <li class="mb-2">
         Documento que evidencie la cobertura total del personal en el acceso a la prestación de servicios de guardería, con información desagregada por sexo.
      </li>

      <li class="mb-2">
         Prueba de que se proporciona a madres y padres o tutores/as una oferta de cuidado de menores complementaria a la que se tiene mediante los servicios de seguridad social (IMSS, ISSSTE o el sistema de seguridad social con el que se cuente en el centro de trabajo).
      </li>

      <li class="mb-2">
         Informe del uso de medidas de flexibilización de horarios (horarios flexibles o escalonados, semanas de trabajo comprimidas, teletrabajo y licencias y permisos con y sin goce de sueldo) con información desagregada por sexo.
      </li>

      <li class="mb-2">
         Que se realicen entrevistas o se muestren pruebas documentadas de la aplicación de las medidas para la flexibilización de los horarios de trabajo, de acuerdo con el informe que se presente, en los casos que el personal auditor lo considere necesario durante las auditorías interna, de certificación y vigilancia.
      </li>

      <li class="mb-2">
         Informe del uso de medidas para el cuidado a dependientes y terceros que reconozcan la diversidad de familias y hogares, con información desagregada por sexo.
      </li>

      <li class="mb-2">
         Que se realicen entrevistas o se muestren pruebas documentadas la aplicación de las medidas para atender las necesidades de cuidados a dependientes y terceros, de acuerdo con el informe que se presente, en los casos que el personal auditor lo requiera durante las auditorías interna, de certificación y vigilancia.
      </li>

      <li class="mb-2">
         Documento que evidencie la difusión de la licencia de paternidad entre el personal o informe del uso de la licencia de paternidad.
      </li>

   </ul>
   `,
   subContents: [
   ]
});