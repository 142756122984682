import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   La auditoría interna es una herramienta de apoyo para evaluar la conformidad de las políticas y las prácticas del centro de trabajo respecto a los requisitos de la Norma Mexicana, y de esa manera contar con un panorama previo a la auditoría de certificación. Por esto es recomendable que el equipo auditor interno cuente con las competencias necesaria para llevar a cabo este ejercicio. 
   <br><br>
   El equipo auditor interno puede estar conformado por personal de la organización, o si el centro de trabajo así lo desea, por personal externo que haya sido contratado para ese fin.
   <br><br>
   Ya que la Norma Mexicana pide que la auditoría interna contemple todos los requisitos establecidos en ella, el centro de trabajo debe aplicar el cuestionario de percepción de clima laboral y no discriminación del personal en el espacio laboral incluido en el Apéndice Normativo E a una muestra de trabajadoras y trabajadores resultado de la fórmula que aparece en el requisito 5.
   <br><br>
   La auditoría interna debe realizarse antes de la auditoría de certificación y antes de la auditoría de vigilancia.

   `,
   subContents: [
   ]
});