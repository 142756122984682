import { Component, OnInit, Input } from '@angular/core';

import { Topic } from 'src/app/models/topic.model';

@Component({
  selector: 'app-menu-index',
  templateUrl: './menu-index.component.html',
  styleUrls: ['./menu-index.component.scss']
})
export class MenuIndexComponent implements OnInit {

  @Input() topics: Array<Topic> = [];

  ngOnInit(): void {
    
  }

}
