import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-2/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-2/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-2/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-2/herramientas.data';
import { REQUISITO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_2_TOPIC: Topic = new Topic({
   idTopic: '60a8a68bda8b34d3d318d9ff8dfbae8d',
   order: 1,
   title: 'Requisito 2: Comité para la igualdad laboral y no discriminación',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.second
});