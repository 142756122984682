import { Component, OnInit } from '@angular/core';
import { uid } from 'uid';

@Component({
  selector: 'app-uid-generator-page',
  templateUrl: './uid-generator-page.component.html',
  styleUrls: ['./uid-generator-page.component.scss']
})
export class UidGeneratorPageComponent implements OnInit {

  public uid: string = uid(32).toString();

  ngOnInit(): void {
    
  }
}
