import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Este requisito se compone de diversos elementos, que deben ser cubiertos en su totalidad y de forma independiente para obtener un puntaje por cada uno de ellos.
   <br><br>
   Para el primer elemento, se deben presentar fotografías y debe haber una visita del personal auditor, en donde se muestre el mobiliario y equipo con ajustes razonables, entendiendo que éstos se refieren a aquellas modificaciones y adaptaciones necesarias y adecuadas en la infraestructura y los servicios, principalmente en el mobiliario de uso laboral, que al realizarlas, no impongan una carga desproporcionada o afecten derechos de terceros, que se aplican cuando se requieran en un caso particular, para garantizar que las personas gocen o ejerzan sus derechos en igualdad de condiciones con las demás. Asimismo, se sugiere que el centro de trabajo por su propia motivación genere evidencia sobre la planeación y construcción de cualquier nuevo espacio del centro de trabajo con un diseño universal.
   <br><br>
   El segundo elemento a su vez, es aquel documento que contenga los criterios y recomendaciones técnicas para la aplicación de ajustes razonables y modificaciones de accesibilidad en los entornos laborales, para facilitar su uso de la forma más autónoma, segura y confortable posible a todas las personas trabajadoras y visitantes, con especial atención al personal con discapacidad.
   <br><br>
   Así mismo en el tercer elemento se mostrará con documentación escrita o digital interna que la información y comunicación es accesible para la totalidad del personal basada en mecanismos y alternativas comunicativas y técnicas que posibilitan el acceso a la comunicación e información de todas las personas, especialmente de las personas con discapacidad y a las personas adultas mayores. Es decir, mejorar la información y comunicación de manera que sea clara, concisa y de fácil entendimiento, eliminando cualquier barrera en la comunicación de los diferentes grupos; por ejemplo: si consideramos accesibilidad para personas ciegas, debemos implementar herramientas, como audios, que les permitan acceder a la información. 
   <br><br>
   Se considera como medios de comunicación e información no sólo a aquellos que tienen como finalidad la comunicación personal e interpersonal, sino también los que tienen por finalidad la comunicación con el medio y el entorno, incluyendo los medios de comunicación de masas como páginas web.
   <br><br>
   La página web del centro de trabajo deberá cumplir con el nivel de accesibilidad mínimo AA, entendiendo las preferencias de las personas usuarias como brindarles la posibilidad de acceder a la información, en formatos accesibles para las diversas discapacidades, con la finalidad de que las personas no requieran de más herramientas de apoyo para su utilización. 
   <br><br>
   Dentro del tercer elemento, también se debe contar con un programa de protección civil, el cual debe ser general para la totalidad del personal pero debe incluir elementos específicos para atender las necesidades de los grupos poblacionales señalados (personas adultas mayores y personas con discapacidad). Esto no quiere decir que las personas con discapacidad o personas adultas mayores deban formar parte de las diferentes brigadas de protección civil.
   <br><br>
   Algunos elementos que podrían incluirse en los programas de protección civil para cumplir con el requisito son:
   <br><br>
   <ul>
      <li class="mb-2">
         Instalación de alarmas sonoras
      </li>
      <li class="mb-2">
         Instalación de alarmas lumínicas
      </li>
      <li class="mb-2">
         Capacitación a todo el personal, en la que se incluya la invitación a dar aviso de la alarma a las personas con discapacidad que lo requieran, en caso de que no se cuente con alarmas sonoras o lumínicas.
      </li>
      <li class="mb-2">
         Incluir en las brigadas de protección civil a alguien que sepa cómo proporcionar auxilio a personas con discapacidad.
      </li>
      <li class="mb-2">
         Colocación de material de emergencia a una altura donde las personas usuarias en silla de ruedas puedan alcanzarlo.
      </li>
      <li class="mb-2">
         Considerar la accesibilidad de la información referente a planes y procedimientos para las personas con discapacidad (audios, lenguaje braille, lengua de señas mexicana y disposición documental por diferentes medios)
      </li>
      <li class="mb-2">
         Disponer de rutas de evacuación accesibles
      </li>
      <li class="mb-2">
         Señalética adecuada
      </li>
      <li class="mb-2">
         Brindar las condiciones adecuadas para su arribo a las zonas de repliegue y/o de concentración
      </li>
      <li class="mb-2">
         Directorio con números de emergencia en formato accesible
      </li>
   </ul>
   <br><br>
   Finalmente, el cuarto elemento relativo a la existencia de espacios físicos adaptados a las necesidades de toda persona se refiere a la existencia de espacios accesibles para todas las personas que laboran en el centro de trabajo, de manera que independientemente de sus características físicas o discapacidad ya sea permanente o temporal, puedan acceder a cualquier espacio, lugar u oficina del centro de trabajo. Cabe señalar que, a diferencia de los ajustes razonables, este elemento se refiere más a características de la infraestructura de acceso, baños accesibles, ascensores, escaleras y pasillos (en general a características de la infraestructura del edifico o local).
   `,
   subContents: [
   ]
});