import { Content } from "src/app/models/content.model";

export const FAQ7: Content = new Content({
    idContent: '4cc4b292b4f8c2b69dc4b618d6e3f930',
    order: 7,
    title: '¿En qué momento se puede solicitar una asesoría por parte del Consejo Interinstitucional?',
    content: `
        En cualquier momento, si se envía una solicitud vía correo electrónico. El horario de atención vía
        telefónica y presencial es de lunes a viernes de 8:00 a 17:00 horas. Se sugiere agendar una cita
        para cualquier asesoría presencial o mediante videoconferencia.
    `,
    subContents: []
});
