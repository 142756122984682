import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   El centro de trabajo debe de contar de forma
   expresa con un proceso de reclutamiento y selección
   de personal, con lenguaje incluyente
   y libre de cualquier tipo de discriminación, de
   acuerdo con la fracción III del artículo 1º de la
   LFPED. Se recomienda que en el manual de
   reclutamiento y selección de personal que
   describa dicho proceso se haga referencia a
   todas las evidencias que forman parte de este
   requisito para mostrarlo de manera integral.
   <br><br>
   Debe establecerse una declaratoria de prohibición
   de solicitud de certificados médicos de no
   embarazo y de Virus de Inmunodeficiencia Humana
   (VIH) y libre de cualquier expresión discriminatoria
   de acuerdo a la fracción III del artículo
   1º de la LFPED. Una posibilidad es hacerlo en la
   publicación de las vacantes.
   <br><br>
   La declaración explícita de esta prohibición tiene
   la función de regular las conductas de las
   personas que intervienen en los procesos de
   reclutamiento y selección, con el fin de evitar
   gestiones discrecionales y/o discriminatorias.
   Además, esto se tiene que hacer del conocimiento
   de las personas que se postulan para
   los puestos vacantes.
   <br><br>
   Para evitar alguna distinción injustificada y arbitraria
   relacionada con las características de una
   persona o su pertenencia a algún grupo en situación
   de vulnerabilidad específico, el proceso de
   entrevista, exámenes o evaluaciones de ingreso,
   deben estar estructurados libres de sesgos sexistas o de cualquier tipo de discriminación de
   acuerdo a la fracción III del artículo 1º de la LFPED.
   `,
   subContents: [
   ]
});