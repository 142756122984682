import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   <ul>
   
      <li class="mb-2">
         Documentos, imágenes o fotografías que se hayan utilizado en el centro de trabajo y que comprueben que la comunicación interna y externa se efectuó con lenguaje incluyente, no sexista y accesible.
      </li>

      <li class="mb-2">
         Uso de lenguaje incluyente y no sexista en toda la documentación que se use como evidencia para el cumplimiento de los requisitos de la norma, así como la comunicación interna y externa del centro de trabajo que se muestre durante las auditorías interna, de certificación y de vigilancia.
      </li>

   </ul>
   `,
   subContents: [
   ]
});