import { Content } from "src/app/models/content.model";

export const FAQ2: Content = new Content({
    idContent: '79a07827ed638ede6b2bf0f2b864cc6e',
    order: 2,
    title: '¿Una vez emitido el folio de registro, existe un plazo para realizar la certificación?',
    content: `
      No hay un plazo determinado, sin embargo, se sugiere obtener la certificación lo antes posible,
      sobre todo porque el organismo de certificación solicitará al centro de trabajo las constancias de No Incumplimiento a la Ley Federal del
      Trabajo y a la Ley Federal para Prevenir y Eliminar la Discriminación, mismas que solo cuentan con 6 meses de vigencia. Transcurrido ese
      tiempo, se tendrían que solicitar dichas constancias nuevamente.
    `,
    subContents: []
});
