import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con una Política de igualdad laboral y no discriminación en el centro de trabajo o equivalente.
   <br><br>
   Garantizar que todas las personas puedan participar de forma igualitaria en todas las actividades y prácticas en el centro de trabajo, entre ellas las siguientes: la formación profesional, la capacitación, el adiestramiento; el acceso, ascenso y permanencia en el empleo; la conciliación entre la vida laboral, la familiar y la personal; el acceso a la seguridad social, a la igualdad salarial y a la no discriminación: Todo lo anterior para poner de manifiesto el compromiso del centro de trabajo con el respeto y garantía de los derechos humanos y laborales, con igualdad de oportunidades y perspectiva de género. 
   `,
   subContents: [
   ]
});