import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   El grupo, comisión o comité para la igualdad laboral y no discriminación deberá estar integrado equitativamente por mujeres y hombres de manera proporcional respecto a la población total del alcance. Si bien la norma solamente especifica que las y los integrantes deberán ser de diversas áreas de responsabilidad, se recomienda que esta diversidad sea representativa tanto de las áreas como de los niveles jerárquicos dentro de la estructura de la organización.
   <br><br>
   Es una decisión del centro de trabajo quiénes y cuántas personas conforman este grupo, comisión o comité. Sin embargo, es conveniente que esté presente el área de recursos humanos, así como otras relacionadas con el cumplimiento de los requisitos de la norma.
   <br><br>
   Cuando el total de trabajadores/as sean 20 o menos, se deberá asignar al menos dos personas para que integren dicho comité, grupo o comisión y den seguimiento para el cumplimiento de los requisitos de esta Norma Mexicana.
   <br><br>
   En caso de optar por retomar a las personas integrantes de otro grupo, comisión o comité existente en el centro de trabajo, se deben cumplir con los siguientes requerimientos:
   <br><br>
   <ul>
      <li class="mb-2">
         Estar integrado equitativamente por mujeres y hombres de manera proporcional respecto a la población total del alcance, ellas y ellos deben provenir de diversas áreas de responsabilidad.
      </li>
      <li class="mb-2">
         Establecer responsabilidades, funciones y grado de autoridad específicos para el grupo, comisión o comité de la norma.
      </li>
      <li class="mb-2">
         Generar documentos de evidencia, entre ellos el acta de instalación, con una antigüedad máxima de 12 meses al momento de la aplicación de la auditoría de certificación y estar suscritos por la máxima autoridad, alta dirección o representante legal del centro de trabajo.
      </li>
      <li class="mb-2">
         No se aceptará otro documento que no cumpla con los requisitos de la Norma Mexicana
      </li>
   </ul>
   `,
   subContents: [
   ]
});