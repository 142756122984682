import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-5/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-5/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-5/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-5/herramientas.data';
import { REQUISITO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_5_TOPIC: Topic = new Topic({
   idTopic: '411ddc420f813b37aac779ef9c8876f0',
   order: 1,
   title: 'Requisito 5: Clima laboral',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.fifth
});