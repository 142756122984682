import { Topic } from 'src/app/models/topic.model';

import { INTRO_TOPIC } from "src/app/data/topics/que-es-la-norma/intro.data";
import { BENEFITS } from "src/app/data/topics/que-es-la-norma/benefits.data";
import { WHO_CAN_BE_CERTIFIED_TOPIC } from 'src/app/data/topics/que-es-la-norma/quienes-pueden-certificarse.data'
import { environment } from 'src/environments/environment';

export const WHAT_IS_NORM_TOPIC: Topic = new Topic({
   idTopic: '4fb997ea4062130b7717034ef31834a2',
   order: 1,
   title: '¿Qué es la Norma Mexicana?',
   subTopics: [
      INTRO_TOPIC,
      BENEFITS,
      WHO_CAN_BE_CERTIFIED_TOPIC,
   ],
   contents: [
   ],
   primaryColor: environment.colors.second
});