import { Topic } from 'src/app/models/topic.model';
import { MEDIDA_4_DESCRIPCION } from '../../contents/medidas/medida-4/medida-4-descripcion.data';
import { MEDIDA_4_EVIDENCIAS } from '../../contents/medidas/medida-4/medida-4-evidencias.data';
import { MEDIDA_4_OBJETIVO } from '../../contents/medidas/medida-4/medida-4-objetivo.data';
import { MEDIDA_4_INTRO } from '../../contents/medidas/medida-4/medida-4-intro.data';
import { environment } from 'src/environments/environment';

export const MEDIDA_4: Topic = new Topic({
   idTopic: '49df4d77982750cf9ea5eaddb8fd5eb8',
   order: 3,
   title: 'Medida 4: Figura mediadora u Ombudsman',
   contents: [
    MEDIDA_4_INTRO,
    MEDIDA_4_OBJETIVO,
    MEDIDA_4_DESCRIPCION,
    MEDIDA_4_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.first
});
