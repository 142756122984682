import { Component } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public collapsed = true;
  public idDoc: string = '';
  public logoInMujeres: string = environment.logos.inMujeres;

  constructor(
    public documentService: DocumentService,
  ) {
    this.idDoc = documentService.docs[0].idDoc;
  }
}
