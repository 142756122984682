import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { DocPageComponent } from './pages/doc-page/doc-page.component';
import { EditorComponent } from './components/editor/editor.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { UidGeneratorPageComponent } from './pages/uid-generator-page/uid-generator-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'docs',
        children: [
          {
            path: ':idDoc',
            children: [
              {
                path: '',
                component: DocPageComponent,
              },
              // NOTE Aquí deberían ir las FAQs
              {
                path: 'topics/:idTopic',
                children: [
                  {
                    path: '',
                    children: [
                      {
                        path: '',
                        component: DocPageComponent,
                      },
                      {
                        path: 'subTopics/:idSubTopic',
                        children: [
                          {
                            path: '',
                            component: DocPageComponent,
                          },
                          {
                            path: 'details/:idDetail',
                            component: DocPageComponent,
                          },
                        ]
                      }
                    ]
                  },
                  // {
                  //   path: 'contents/:idContent',
                  //   children: [
                  //     {
                  //       path: '',
                  //       component: DocPageComponent,
                  //     },
                  //   ]
                  // }
                ]
              }
            ]
          } 
        ]
      },
      {
        path: 'utils',
        children: [
          {
            path: 'uid-generator',
            component: UidGeneratorPageComponent
          },
          {
            path: 'editor',
            component: EditorComponent
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
