import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Medir el clima laboral y no discriminación en el centro de trabajo. 
   <br><br>
   Conocer la percepción que tienen las personas que laboran en el centro de trabajo, dentro del alcance de la certificación, sobre aspectos de igualdad laboral y cultura institucional de no discriminación en su entorno laboral que facilitan o dificultan su desempeño.
   `,
   subContents: [
   ]
});