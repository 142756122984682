import { Topic } from 'src/app/models/topic.model';
import { AUDITORIA_DESCRIPCION } from '../../contents/auditoria/auditoria-descripcion.data';
import { AUDITORIA_OBJETIVO } from '../../contents/auditoria/auditoria-objetivo.data';
import { AUDITORIA_EVIDENCIAS } from '../../contents/auditoria/auditoria-evidencias.data';
import { environment } from 'src/environments/environment';

export const AUDITORIA_DE_VIGILANCIA: Topic = new Topic({
   idTopic: '7e386b4e2cb856f6898da2ab073c252b',
   order: 1,
   title: 'Auditoría de vigilancia',
   contents: [
    AUDITORIA_OBJETIVO,
    AUDITORIA_DESCRIPCION,
    AUDITORIA_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.third
});