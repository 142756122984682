import { Content } from 'src/app/models/content.model';

export const REQUISITOS_DESCRIPCION: Content = new Content({
   idContent: 'dc1cb2cdd25e7d7f17fcac396963fd3b',
   order: 1,
   title: '',
   content: `
   Son aquellos cuya evaluación de la conformidad requiere que los centros de trabajo demuestren
   el trabajo sustantivo en los temas que se incluyen en la Norma Mexicana. Permiten al centro de
   trabajo demostrar la implementación de políticas y acciones encaminadas a conseguir la igualdad
   laboral y la no discriminación en sus actividades diarias.
   <br><br>
   Su cumplimiento es indispensable para obtener la certificación, tienen un valor de 25 puntos.
   `,
   subContents: []
});