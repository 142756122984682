import { Content } from 'src/app/models/content.model';

export const INFOGRAFIA_BENEFICIOS: Content = new Content({
   idContent: '8983002e91dc84d379bb88af5eac0626',
   order: 1,
   title: '',
   content: `
    <div class="row">
        <div class="col-12">
          <img src="../../../assets/infografias/inmujeres-manual-b-graficos2.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </div>
    </div>
   `,
   subContents: []
});
