import { Content } from 'src/app/models/content.model';

export const AUDITORIA_OBJETIVO: Content = new Content({
   idContent: 'd4880f552888cb8e65e2a5ce3d6d0b6f',
   order: 1,
   title: 'Objetivo',
   content: `
    Demostrar el mantenimiento de las condiciones que propiciaron la certificación original, es decir, la revisión de la totalidad de los requisitos que la norma señala, para lograr que la certificación otorgada siga cumpliendo con su objetivo original, que es el de propiciar el respeto al derecho a la igualdad laboral y no discriminación en lo formal y lo real.
   `,
   subContents: []
});
