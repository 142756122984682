import { Content } from "src/app/models/content.model";

export const FAQ9: Content = new Content({
    idContent: 'ce5ed423a6c9390dcdc3bbb3af10a70a',
    order: 9,
    title: '¿Se puede presentar como evidencia del requisito 5 (Medir el clima laboral y no discriminación en el centro de trabajo) el cuestionario que el propio centro de trabajo diseñe o haya implementado anteriormente?',
    content: `
      No, necesariamente tiene que ser el contenido en el Apéndice Normativo E de la Norma Mexicana, ya que las preguntas han sido formuladas
      estratégicamente para medir de forma efectiva el clima laboral y la no discriminación en el centro de trabajo.
    `,
    subContents: []
});
