import { Content } from 'src/app/models/content.model';

export const MEDIDA_3_INTRO: Content = new Content({
   idContent: 'b21f4e45d796a37ea72a423c54a21c4c',
   order: 1,
   title: '',
   content: `
      Contar con personal con discapacidad en una proporción del 5 % cuando menos de la totalidad de su personal.
   `,
   subContents: []
});
