import { ITopic, Topic } from 'src/app/models/topic.model';
import { IDocFile, DocFile } from  'src/app/models/docfile.model';
import { IContent, Content } from './content.model';

export interface IDoc {
   idDoc: string;
   name: string;
   topics: Array<ITopic>;
   faqs: Array<IContent>;
   docfiles: Array<IDocFile>;
}

export class Doc {
   idDoc: string;
   name: string;
   topics: Array<Topic>;
   faqs: Array<IContent>;
   docfiles: Array<DocFile>;

   constructor(data: IDoc) {
      this.idDoc = data.idDoc;
      this.name = data.name;
      this.faqs = data.faqs
         ? data.faqs.map(faq => new Content(faq))
         : [];
      this.topics = data.topics
         ? data.topics.map(topic => new Topic(topic))
         : [];
      this.docfiles = data.docfiles
         ? data.docfiles.map(docfile => new DocFile(docfile))
         : [];
   }
}
