<div 
   class="container-fluid">
   <div class="row">

      <div 
         class="col-12 row"
         *ngFor="let topic of topics">
         <a 
            *ngIf="topic.idTopic != idTopic"
            class="col-12 topic font-weight-700 text-decoration-none text-black"
            (click)="accordion?.collapseAll()"
            [routerLink]="['/docs', idDocument, 'topics', topic.idTopic]"
            [ngClass]="{
               'mb-3': topic.subTopics.length == 0,
               'mb-1': topic.subTopics.length > 0 
            }">
            {{ topic.title }}
         </a>

         <a 
            *ngIf="topic.idTopic == idTopic"
            class="col-12 topic font-weight-700 text-decoration-none"
            (click)="accordion?.collapseAll()"
            [routerLink]="['/docs', idDocument, 'topics', topic.idTopic]"
            [ngClass]="{
               'mb-3': topic.subTopics.length == 0,
               'mb-1': topic.subTopics.length > 0 
            }"
            [ngStyle]="{
               'color': topic.primaryColor
            }">
            {{ topic.title }}
         </a>

         <div class="col-12 mb-3 row">
            
            <div 
               *ngFor="let subTopic of topic.subTopics"
               class="col-12 row">
               <a 
                  *ngIf="subTopic.idTopic != idTopic"
                  (click)="accordion?.collapseAll()"
                  [routerLink]="['/docs', idDocument, 'topics', topic.idTopic, 'subTopics', subTopic.idTopic]"
                  class="col-12 ps-4 text-decoration-none sub-topic font-weight-500 mb-2 text-secondary">
                  {{ subTopic.title }}
               </a>

               <a 
                  *ngIf="subTopic.idTopic == idTopic"
                  (click)="accordion?.collapseAll()"
                  [routerLink]="['/docs', idDocument, 'topics', topic.idTopic, 'subTopics', subTopic.idTopic]"
                  class="col-12 ps-4 text-decoration-none sub-topic font-weight-500 mb-2"
                  [ngStyle]="{
                     'color': subTopic.primaryColor
                  }">
                  {{ subTopic.title }}
               </a>

               <div 
                  *ngIf="subTopic.subTopics.length > 0"
                  class="col-12 mb-3 row">
                  
                  <div 
                     class="col-12 ps-5 pe-0"
                     *ngFor="let detail of subTopic.subTopics">

                     <a
                        *ngIf="detail.idTopic == idTopic"
                        (click)="accordion?.collapseAll()"
                        [routerLink]="['/docs', idDocument, 'topics', topic.idTopic, 'subTopics', subTopic.idTopic, 'details', detail.idTopic]"
                        class="col-12 p-0 text-decoration-none sub-topic font-weight-300 mb-2"
                        [ngStyle]="{
                           color: detail.primaryColor
                        }">
                        {{ detail.title }}
                     </a>

                     <a
                        *ngIf="detail.idTopic != idTopic"
                        (click)="accordion?.collapseAll()"
                        [routerLink]="['/docs', idDocument, 'topics', topic.idTopic, 'subTopics', subTopic.idTopic, 'details', detail.idTopic]"
                        class="col-12 p-0 text-decoration-none sub-topic font-weight-300 mb-2 text-secondary">
                        {{ detail.title }}
                     </a>

                  </div>
                  
               </div>
            </div>
            
         </div>
      </div>

   </div>
</div>