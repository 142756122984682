import { Topic } from 'src/app/models/topic.model';
import { MEDIDA_2_DESCRIPCION } from '../../contents/medidas/medida-2/medida-2-descripcion.data';
import { MEDIDA_2_EVIDENCIAS } from '../../contents/medidas/medida-2/medida-2-evidencias.data';
import { MEDIDA_2_OBJETIVO } from '../../contents/medidas/medida-2/medida-2-objetivo.data';
import { MEDIDA_2_INTRO } from '../../contents/medidas/medida-2/medida-2-intro.data';
import { environment } from 'src/environments/environment';

export const MEDIDA_2: Topic = new Topic({
   idTopic: 'c42144f85545147e25093fce0d28f084',
   order: 2,
   title: 'Medida 2: Integración puestos directivos',
   contents: [
    MEDIDA_2_INTRO,
    MEDIDA_2_OBJETIVO,
    MEDIDA_2_DESCRIPCION,
    MEDIDA_2_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.fifth
});
