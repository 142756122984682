export interface IContent {
   idContent: string;
   order: number;
   title: string;
   content: string;
   subContents: Array<IContent>;
   kindOfContent?: string;
}

export class Content {
   idContent: string;
   order: number;
   title: string;
   content: string;
   subContents: Array<Content>;
   kindOfContent?: string;

   constructor(data: IContent) {
      this.idContent = data.idContent;
      this.order = data.order;
      this.title = data.title;
      this.content = data.content;
      this.subContents = data.subContents 
         ? data.subContents.map(subContent => new Content(subContent))
         : [];
      this.kindOfContent = data.kindOfContent ?? '';
   }

   getLetter(index: number): string {

      let letter = '';

      if(this.kindOfContent == 'subsection') {
         switch (index) {
            case 0:
               letter = 'A';
            break;

            case 1:
               letter = 'B';
               break;

            case 2:
               letter = 'C';
            break;

            case 3:
               letter = 'D';
            break;

            case 4:
               letter = 'E';
            break;

            case 5:
               letter = 'F';
            break;

            case 6:
               letter = 'G';
            break;

            case 7:
               letter = 'H';
            break;

            case 8:
               letter = 'I';
            break;

            case 9:
               letter = 'J';
            break;
         
            default:
               break;
         }
      }

      return letter;
   }
}
