import { Topic } from 'src/app/models/topic.model';
import { FAQ1 } from '../faqs/faq-1';
import { FAQ10 } from '../faqs/faq-10';
import { FAQ11 } from '../faqs/faq-11';
import { FAQ12 } from '../faqs/faq-12';
import { FAQ13 } from '../faqs/faq-13';
import { FAQ14 } from '../faqs/faq-14';
import { FAQ15 } from '../faqs/faq-15';
import { FAQ2 } from '../faqs/faq-2';
import { FAQ3 } from '../faqs/faq-3';
import { FAQ4 } from '../faqs/faq-4';
import { FAQ5 } from '../faqs/faq-5';
import { FAQ6 } from '../faqs/faq-6';
import { FAQ7 } from '../faqs/faq-7';
import { FAQ8 } from '../faqs/faq-8';
import { FAQ9 } from '../faqs/faq-9';
import { environment } from 'src/environments/environment';

export const FAQS: Topic = new Topic({
   idTopic: '807d1b53cfcd5526f47f5aafd8eec221',
   order: 4,
   title: `Preguntas frecuentes`,
   subTopics: [],
   contents: [
        FAQ1,
        FAQ2,
        FAQ3,
        FAQ4,
        FAQ5,
        FAQ6,
        FAQ7,
        FAQ8,
        FAQ9,
        FAQ10,
        FAQ11,
        FAQ12,
        FAQ13,
        FAQ14,
        FAQ15
   ],
   primaryColor: environment.colors.fifth
});