import { Content } from 'src/app/models/content.model';

export const INFOGRAFIA_EJES: Content = new Content({
   idContent: '07400642da9d3e2c64529d2ebec47ee1',
   order: 1,
   title: '',
   content: `
    <div class="row">
        <a
          class="col-12"
          href="https://www.gob.mx/cms/uploads/attachment/file/25111/NMX-R-025-SCFI-2015_2015_DGN.pdf"
          target="_blank">
          <img src="../../../assets/infografias/INMUJERES_manual_b_graficos.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </a>
    </div>
   `,
   subContents: []
});
