import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con accesibilidad en los centros de trabajo.
   <br><br>
   Establecer condiciones de información, movilidad y de seguridad, para proteger la integridad física y salud de las y los trabajadores que laboren en el centro de trabajo, y así poder desarrollar sus actividades sin ninguna barrera.
   `,
   subContents: [
   ]
});