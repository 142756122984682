<div class="container-fluid">
   <div class="row">

      <div 
         class="col-12 text-white mb-3"
         [ngStyle]="{
            backgroundColor: topic.primaryColor
         }">
         <div class="row">

            <div class="col-12 topic-title font-weight-200">
               {{topic.title}}
            </div>
            <!-- <div class="col-12 mb-5">
               <hr class="with-decoration">
            </div> -->

         </div>
      </div>

      <app-item-content class="col-12 mb-4"
         *ngFor="let content of topic.contents"
         [content]="content"
         [primaryColor]="topic.primaryColor">
      </app-item-content>

   </div>
</div>