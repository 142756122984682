import { Topic } from 'src/app/models/topic.model';
import { STEP_1_CONTENT } from 'src/app/data/contents/como-certificar/pasos-certificar/paso-1.data';
import { STEP_1_DIAGRAM } from '../../contents/como-certificar/pasos-certificar/pasos-diagrama.data';
import { environment } from 'src/environments/environment';
import { STEP_1_DIAGRAM_IMG } from '../../contents/como-certificar/pasos-certificar/pasos-img-.data';

export const PASOS_CERTIFICACION_TOPIC: Topic = new Topic({
   idTopic: '3a734b27b643e0c82c5f23fe0f9ed267',
   order: 1,
   title: 'Pasos para la certificación',
   subTopics: [
      
   ],
   contents: [
      STEP_1_DIAGRAM,
      // STEP_1_DIAGRAM_IMG,
      STEP_1_CONTENT
   ],
   primaryColor: environment.colors.fourth
});