import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   <!-- Cuestionario de percepción de clima laboral y no discriminación, apéndice E 
   <br><br> -->
   <div class="row">
      <div class="col-12 row">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/56675/5._Cuestionario_de_percepcion_de_clima_laboral.pdf'"
               [title]="'Cuestionario de percepción de clima laboral y no discriminación'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   <br>
   Fórmula para calcular el tamaño de muestra (Inmujeres)
   `,
   subContents: [
   ]
});