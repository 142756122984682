import { Content } from 'src/app/models/content.model';

export const PADRON_ENLACE: Content = new Content({
   idContent: '65d0eb53fbf570a53a81ea23608a7e48',
   order: 1,
   title: 'Más información',
   content: `
    En el siguiente enlace encontrarás información sobre el Padrón Nacional:
    <br><br>
    <div class="row d-flex justify-content-center align-items-center"> 
        <div class="col-6">
            <a
                href="https://www.gob.mx/normalaboral/articulos/padron-nacional-de-centros-de-trabajo-certificado"
                class="rounded rounded-4 text-decoration-none text-white bg-primary py-2 px-3 d-flex justify-content-center align-items-center"
                target="_blank"
                >
                <span class="font-weight-600 me-2">
                    Padrón Nacional
                </span>
                <i class="material-icons">link</i>
            </a>
        </div>
    </div>
   `,
   subContents: []
});