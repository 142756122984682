import { Content } from 'src/app/models/content.model';

export const STEP_1_DIAGRAM: Content = new Content({
   idContent: '6dd79cbb909003d5882648f9fe8afb09',
   order: 1,
   title: '',
   content: `
    <div class="row">
        <div class="col-12 py-2">
          <div class="rounded rounded-3 text-decoration-none text-white bg-seventh py-2 px-3 d-flex justify-content-center align-items-center">
            <span class="font-weight-600 me-2">
              1. Conocer los requisitos de la Norma Mexicana y realizar el Diagnóstico de Autoevaluación
            </span>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../../assets/flecha-hacia-abajo.png"
                alt="MDN"
                width="32"
                height="32">
        </div>
        <div class="col-12 py-2">
            <div class="rounded rounded-3 text-decoration-none text-white bg-ninth py-2 px-3 d-flex justify-content-center align-items-center">
              <span class="font-weight-600 me-2">
                2. Implementar o adecuar los procesos y prácticas para el cumplimiento de los requisitos
              </span>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../../assets/flecha-hacia-abajo.png"
                alt="MDN"
                width="32"
                height="32">
        </div>
        <div class="col-12 py-2">
            <div class="rounded rounded-3 text-decoration-none text-white bg-ten-first py-2 px-3 d-flex justify-content-center align-items-center">
              <span class="font-weight-600 me-2">
                3. Registrar la participación del centro de trabajo ante el Consejo Interinstitucional
              </span>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../../assets/flecha-hacia-abajo.png"
                alt="MDN"
                width="32"
                height="32">
            </div>
        <div class="col-12 py-2">
            <div class="rounded rounded-3 text-decoration-none text-white bg-eighth py-2 px-3 d-flex justify-content-center align-items-center">
              <span class="font-weight-600 me-2">
                4. Realizar el proceso para someterse a la auditoría de certificación
              </span>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../../assets/flecha-hacia-abajo.png"
                alt="MDN"
                width="32"
                height="32">
        </div>
        <div class="col-12 py-2">
            <div class="rounded rounded-3 text-decoration-none text-white bg-sixth py-2 px-3 d-flex justify-content-center align-items-center">
              <span class="font-weight-600 me-2">
                5. Enviar copia del certificado y el formato de Uso de marca al Consejo Interinstitucional
              </span>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../../assets/flecha-hacia-abajo.png"
                alt="MDN"
                width="32"
                height="32">
        </div>
        <div class="col-12 py-2">
            <div class="rounded rounded-3 text-decoration-none text-white bg-tenth py-2 px-3 d-flex justify-content-center align-items-center">
              <span class="font-weight-600 me-2">
                6. Para mantener la certificación, realizar acciones para la mejora continua y programar la auditoría de vigilancia
              </span>
            </div>
        </div>
    </div>
   `,
   subContents: [
   ]
});
