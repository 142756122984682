import { Content } from 'src/app/models/content.model';

export const MEDIDA_1_DESCRIPCION: Content = new Content({
   idContent: 'c2fb9e6b57b4eb25451df242eaeaf4ab',
   order: 1,
   title: 'Descripción',
   content: `
    El centro de trabajo debe contemplar e implementar dentro de su plan de acciones, las necesarias para lograr el objetivo descrito, considerando la apertura a la contratación basada en los talentos y aptitudes de las personas, eliminando las barreras que limitan la contratación de las mujeres que históricamente se ha visto disminuida.
   `,
   subContents: []
});