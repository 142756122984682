import { Content } from "src/app/models/content.model";

export const FAQ11: Content = new Content({
    idContent: '2bda2743a7e7cea437e7a4ca6bdcd9c6',
    order: 11,
    title: 'Para la auditoría de vigilancia ¿se expide un nuevo folio?',
    content: `
      No, el folio será el mismo que se le brindó al centro de trabajo en su certificación inicial.
    `,
    subContents: []
});
