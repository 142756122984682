import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Mecanismos y regulaciones para prevenir, atender y sancionar las prácticas de discriminación y violencia laboral en el centro de trabajo.
   <br><br>
   Operar un modelo de prevención, atención y sanción que las y los trabajadores puedan utilizar cuando ocurran actos de discriminación y violencia laboral fundada en prejuicios y estigmas discriminatorios que se ejerzan en su contra dentro del centro de trabajo, con el propósito general de gestar paulatinamente espacios laborales libres de violencia en todas sus formas.
   `,
   subContents: [
   ]
});