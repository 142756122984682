import { Content } from 'src/app/models/content.model';

export const INFOGRAFIA_QUIENES_PUEDEN_CERTIFICARSE: Content = new Content({
   idContent: '775c183c73346dd58f61edb9f6882a97',
   order: 1,
   title: '',
   content: `
    <div class="row">
        <div class="col-12">
          <img src="../../../assets/infografias/inmujeres-manual-b-graficos3.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </div>
    </div>
   `,
   subContents: []
});
