import { Content } from 'src/app/models/content.model';

export const STEP_3_CONTENT: Content = new Content({
   idContent: '2054a299320ce7584b8ec66474e4656b',
   order: 1,
   title: 'Paso 3: Solicitar la Constancia',
   content: `
   Solicitar la Constancia de no incumplimiento a la Ley Federal para Prevenir y Eliminar la Discriminación (quejas procedentes) con una antigüedad de seis meses, deberá formular dicha solicitud mediante escrito dirigido a la Jefatura de Departamento de Admisión y registro al correo: hberdejao@conapred.org.mx al tel: (55)52621490 ext. 5436.  El periodo de respuesta es de 15 días hábiles y la revisión consta de un año anterior a la fecha de solicitud del documento.
   <br><br>
   <div class="row d-flex justify-content-center">
      <div class="col-5">
         <app-button-url
            [url]="'https://www.conapred.org.mx/userfiles/files/CONSTANCIA-DE-NO-INCUMPLIMIENTO%20A%20LA%20LFPED-CONAPRED1%282%29.doc'"
            [title]="'Constancia de no incumplimiento'"
            [icon]="'description'">
         </app-button-url>
      </div>
   </div>
   <br>
   Así mismo para solicitar la Constancia de no incumplimiento a la Ley Federal del Trabajo (quejas procedentes) de Profedet lo puede hacer con el siguiente Contacto:
   <br><br>
   Dra. Carolina Ortiz Porras, Titular de la Procuraduría Federal de la Defensa del Trabajo al correo:  buzonconstanciasnmx@stps.gob.mx, dirección Dr. José María Vértiz 211, Col. Doctores, Delegación Cuauhtémoc, 06720, CDMX, Teléfono (55)59982000 ext. 44501 y 44509. El periodo de respuesta es de 8 días hábiles y la revisión consta de un año anterior a la fecha de solicitud del documento. 
   <br><br>
   <div class="row d-flex justify-content-center">
      <div class="col-6">
         <app-button-url
            [url]="'https://www.conapred.org.mx/userfiles/files/FORMATO%20SOLICITUD%20CONSTANCIA%20%20ANTE%20PROFEDET%202020.doc'"
            [title]="'Solicitud constancia ante PROFEDET'"
            [icon]="'description'">
         </app-button-url>
      </div>
   </div>
   `,
   subContents: [
   ]
});