import { Content } from 'src/app/models/content.model';

export const DOCUMENTO_NORMA_CONTENT: Content = new Content({
   idContent: 'b26bfcf03dcb2471f0e3c56e0125fe9d',
   order: 1,
   title: 'Consulta la documentación que hemos puesto a tu disposición',
   content: `
    <div class="row"> 
        <div class="col-6">
            <a
                href="https://www.gob.mx/cms/uploads/attachment/file/398546/Tri_ptico_NMX-R-025-SCFI-2015.pdf"
                class="rounded rounded-4 text-decoration-none text-white bg-primary py-2 px-3 d-flex justify-content-center align-items-center"
                target="_blank"
                >
                <span class="font-weight-600 me-2">
                    Tríptico
                </span>
                <i class="material-icons">save_alt</i>
            </a>
        </div>

        <div class="col-6">
            <a
                href="https://www.gob.mx/cms/uploads/attachment/file/25111/NMX-R-025-SCFI-2015_2015_DGN.pdf"
                class="rounded rounded-4 text-decoration-none text-white bg-primary py-2 px-3 d-flex justify-content-center align-items-center"
                target="_blank"
                >
                <span class="font-weight-600 me-2">
                    Documento de norma mexicana
                </span>
                <i class="material-icons">save_alt</i>
            </a>
        </div>
    </div>
   `,
   subContents: []
});