import { Content } from 'src/app/models/content.model';

export const REQUISITO_NO_CRITICO: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: '',
   content: `
   <b>Requisito no crítico</b>
   `,
   subContents: [
   ]
});