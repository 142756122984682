import { Injectable } from '@angular/core';

import { Banner } from  'src/app/models/banner.model';

import { BANNER01 } from "src/app/data/banners/main-banners/banner-01.data";
import { BANNER02 } from "src/app/data/banners/main-banners/banner-02.data";

@Injectable({
  providedIn: 'root'
})
export class MainBannerService {

  public banners: Array<Banner> = [];

  constructor() { 
    this.banners = [
      BANNER01,
      BANNER02,
    ]
  }
}
