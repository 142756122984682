import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   La transparencia, como eje fundamental de la Norma Mexicana, debe permear fehacientemente los procesos de ascenso y permanencia. La base documental sobre la que se asientan estos procesos deberá ser del conocimiento generalizado del personal, con el objetivo de que todas y todos lo tengan presente al momento de participar o acceder a los mecanismos de ascenso.
   <br><br>
   A su vez, para llevar a cabo los procesos de evaluación del desempeño, el centro de trabajo deberá hacerlos del conocimiento de todo el personal. Esta difusión deberá realizarse con la característica intrínseca de accesibilidad y que se fundamente en planes de desarrollo del personal en forma retroactiva.
   <br><br>
   Los procesos de evaluación del desempeño deberán hacerse del conocimiento de todo el personal, la información relacionada a los procesos deberá tenerse disponible de manera accesible de acuerdo a las necesidades de las trabajadoras y los trabajadores. Además, los procesos de evaluación del desempeño deberán basarse en planes de desarrollo del personal.
   <br><br>
   Entre las evidencias se debe encontrar la demostración documental de la relación entre los procesos de ascenso y permanencia y la evaluación del desempeño.
   `,
   subContents: [
   ]
});