import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Los documentos, comunicaciones, imágenes o fotografías presentadas como evidencia deberán utilizar un lenguaje que considere los tres aspectos: ser incluyente, no ser sexista y ser accesible. Deberá hacerse visibles a las mujeres en el ámbito laboral, así como la diversidad sociocultural existente, prevaleciendo formas de expresión colectivas que no excluyen por encima de expresiones lingüísticas que impliquen diferenciación con fines de exclusión, ejercicio autoritario del poder o subordinación de personas y colectivos.
   <br><br>
   No serán válidas las notas aclaratorias en las que se señala que el uso del masculino incluye tanto a las mujeres como a los hombres. Es necesario que el lenguaje incluyente, no sexista y accesible aparezca a lo largo de los documentos mostrados como evidencia, no únicamente dentro de clarificaciones al inicio o en los glosarios.
   <br><br>
   El lenguaje accesible es aquel que está dirigido a mejorar la información y comunicación del centro de trabajo hacia el personal que lo conforma, haciéndola clara, abierta, fluida, concisa y de fácil entendimiento con la finalidad de eliminar cualquier barrera en la comunicación.
   <br><br>
   Cabe señalar que se tomará en cuenta la documentación de las evidencias de los requisitos de la Norma Mexicana y también la que se prepare específicamente para el cumplimiento de este requisito.

   `,
   subContents: [
   ]
});