<div class="container-fluid">
   <div class="row">

      <app-doc-item 
         [doc]="doc"
         class="col-12 mb-4"
         *ngFor="let doc of documentService.docs">
      </app-doc-item >

      <!-- <div class="col-12 bg-black text-white py-2 rounded text-center">
         Esta caja de herramientas ha sido financiada por Cooperación Danesa, implementada por Expertise France y el apoyo del Instituto Nacional de las Mujeres.
      </div> -->

      <div class="col-12 rounded border py-3">
         <div class="row">

            <div class="col-12 text-center mb-2">
               <b>Esta herramienta de apoyo en línea es una contribución de la asistencia técnica de Expertise France para el Instituto Nacional de las Mujeres que ha sido financiado por la Cooperación Danesa y se enmarca en el propósito de fortalecer la igualdad profesional entre mujeres y hombres en México.</b>
            </div>

            <div class="col-12 text-center">
               <img 
                  src="../../../assets/logos/Mexico City-Spanish [18918].jpg" 
                  width="15%"
                  class="me-5">

               <img 
                  src="../../../assets/logos/expertise-france-logo.png" 
                  width="10%">
            </div>

         </div>
      </div>

   </div>
</div>