import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-4/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-4/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-4/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-4/herramientas.data';
import { REQUISITO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_4_TOPIC: Topic = new Topic({
   idTopic: '8ba4ffc35b9dbbf179c79a5381657526',
   order: 1,
   title: 'Requisito 4: Auditoría interna',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.fourth
});