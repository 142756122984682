export const environment = {
   production: false,
   logos: {
      inMujeres: '../../../assets/logos/Logo_INMUJERES_Mujeres_White_lettters.png'
   },
   infografias: {
      principalesEjes: '../../../assets/infografias/principales_ejes.jpg'
   },
   colors: {
      first: '#ef4026',
      second: '#00adef',
      third: '#f7931d',
      fourth: '#8cc63e',
      fifth: '#652c90',
      sixth: '#716558',
   }
};
