import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-url',
  templateUrl: './button-url.component.html',
  styleUrls: ['./button-url.component.scss']
})
export class ButtonUrlComponent implements OnInit {

  @Input() icon: string = '';
  @Input() title: string = ''
  @Input() url: string = '';

  ngOnInit(): void {
    
  }

}
