import { Content } from 'src/app/models/content.model';

export const AUDITORIA_EVIDENCIAS: Content = new Content({
   idContent: '4a609db8271fc07fdb481e0ba3a3b907',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
    Para la conformación se deberán seguir los mismos pasos descritos para cada uno de los requisitos.
    <br><br>
    Debido a que la Norma establece que todas las evidencias presentadas para el cumplimiento de los requisitos de certificación deberán tener
    una antigüedad máxima de 12 meses al momento de la aplicación de la auditoría de certificación o de vigilancia, se requiere presentar
    documentos que hagan explícito la validez y vigencia de los documentos al momento de la auditoría de vigilancia.
    <br><br>
    Para el caso de aquellos requisitos que no sufren modificaciones (como la política de igualdad y no discriminación), será necesario presentar un documento de declaratoria de vigencia.
    <br><br>
    <ul>
      <li>Evaluación de la conformidad de los requisitos de certificación, incluyendo el cumplimiento del 100% de los puntos críticos.</li>
      <li>Evidencia de la solvencia de al menos 70% las áreas de oportunidad detectadas en la auditoría de certificación.</li>
      <li>Aplicación del cuestionario de clima laboral en la auditoría interna y en la de vigilancia.</li>
      <li>Llevar a cabo la auditoría interna previa a la auditoría de vigilancia</li>
    </ul>
   `,
   subContents: []
});
