import { Topic } from 'src/app/models/topic.model';
import { MEDIDA_3_DESCRIPCION } from '../../contents/medidas/medida-3/medida-3-descripcion.data';
import { MEDIDA_3_EVIDENCIAS } from '../../contents/medidas/medida-3/medida-3-evidencias.data';
import { MEDIDA_3_OBJETIVO } from '../../contents/medidas/medida-3/medida-3-objetivo.data';
import { MEDIDA_3_INTRO } from '../../contents/medidas/medida-3/medida-3-intro.data';
import { environment } from 'src/environments/environment';

export const MEDIDA_3: Topic = new Topic({
   idTopic: 'f3269056f499df3d7dc0bd71919d7694',
   order: 3,
   title: 'Medida 3: Personal con discapacidad',
   contents: [
    MEDIDA_3_INTRO,
    MEDIDA_3_OBJETIVO,
    MEDIDA_3_DESCRIPCION,
    MEDIDA_3_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.sixth
});
