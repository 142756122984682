import { Content } from 'src/app/models/content.model';

import { DESCRIPCION_DEL_TAMAÑO_DEL_SECTOR_PRODUCTIVO } from './sectorProductivo.data';

export const WHO_CAN_BE_CERTIFIED_CONTENT: Content = new Content({
   idContent: 'ba3aa7f6d02d11850ebb5651391c2a48',
   order: 2,
   title: '',
   content: `Pueden certificarse los centros de trabajo de los sectores público, privado o social, de cualquier tamaño, rama económica o giro que se encuentren ubicados en la República Mexicana.
   <br><br>
   <b>**La convocatoria se encuentra abierta durante todo el año.</b>`,
   subContents: [
      DESCRIPCION_DEL_TAMAÑO_DEL_SECTOR_PRODUCTIVO
   ]
});