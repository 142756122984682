import { Content } from 'src/app/models/content.model';

export const BENEFITS_H: Content = new Content({
   idContent: '703f121327375e47d632c259fe770d83',
   order: 1,
   title: 'Responde a la realidad social existente',
   content: `
   La estructura social en la actualidad va más allá de la división que situaba a las mujeres en el ámbito de lo privado y a los hombres en lo público. Ahora las mujeres se encuentran en todos los espacios de la vida cotidiana, desde la educación hasta la política y el mercado laboral. Las empresas no se pueden mostrar ajenas a esta realidad y deben trabajar para que tanto mujeres como hombres estén representados en ellas en igualdad de oportunidades respondiendo así a la realidad social existente.
   `,
   subContents: [],
   kindOfContent: 'subsection'
});