import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá generar como evidencias:
   <br><br>
   <ul>
      <li class="mb-2">
         Fotografías de la infraestructura, mobiliario y equipo con ajustes razonables para personas con discapacidad o personas adultas mayores o mujeres embarazadas.
      </li>
      <li class="mb-2">
         Que se realice una verificación física de la infraestructura, mobiliario y equipo con ajustes razonables para personas con discapacidad o personas adultas mayores o mujeres embarazadas en las auditorías interna, de certificación y vigilancia.
      </li>
      <li class="mb-2">
         Documento que contenga el plan de accesibilidad de espacios físicos para toda persona.
      </li>
      <li class="mb-2">
         Documentación interna o página web accesible (AA) disponible para la totalidad del personal con las siguientes características:
      </li>
         <li class="mb-2">
            Que proporcione un texto equivalente para todo elemento no textual (imágenes o representaciones gráficas).
         </li>
         <li class="mb-2">
            Que utilice el lenguaje apropiado más claro y simple con el contenido del sitio.
         </li>
         <li class="mb-2">
            Que las aplicaciones de usuario puedan leer en voz alta automáticamente el texto o presenten una descripción auditiva.
         </li>
         <li class="mb-2">
            Que proporcione información de modo que las y los usuarios puedan recibir los documentos según sus preferencias, en atención a las diversas discapacidades.
         </li>
      <ul>
      </ul>
      <li class="mb-2">
         Documento que demuestre la existencia de un programa de protección civil que incluya a personas adultas mayores y con discapacidad. 
      </li>
      <li class="mb-2">
         Presentar fotografías de la infraestructura con espacios físicos adaptados a las necesidades de toda persona.
      </li>
      <li class="mb-2">
         Que se realice una verificación física de la infraestructura con espacios físicos adaptados a las necesidades de toda persona en las auditorías interna, de certificación y vigilancia.
      </li>
   </ul>
   `,
   subContents: [
   ]
});