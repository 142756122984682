import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-8/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-8/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-8/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-8/herramientas.data';
import { REQUISITO_NO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/no-critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_8_TOPIC: Topic = new Topic({
   idTopic: '8bd1556bcd699b62a4430b5961785dad',
   order: 1,
   title: 'Requisito 8: Proceso de ascenso y permanencia',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_NO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.second
});