import { Content } from 'src/app/models/content.model';

export const MEDIDA_2_EVIDENCIAS: Content = new Content({
   idContent: 'e214bef87b0d9ea3ca0c10b04edcd492',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
    El centro de trabajo deberá generar como evidencias:
    <br><br>
    <ul>
      <li>Documento que contiene la plantilla de personal en el que se identifique que existe al menos el 40% de las mujeres ocupando puestos
          directivos, para lo que será necesario que dicha plantilla cuente con una sección en la que se desagregue el sexo de las personas contratadas,
      además del nivel jerárquico al que pertenecen.</li>
      <li>Documento en el que se señalen las acciones realizadas de reclutamiento y ascensos que las mujeres ocupen puestos directivos.</li>
    </ul>
   `,
   subContents: []
});
