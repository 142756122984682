import { Content } from "src/app/models/content.model";

export const FAQ3: Content = new Content({
    idContent: '279b39b86b77c6742c6776985f1e53ab',
    order: 3,
    title: '¿Es necesario certificar a la totalidad del centro de trabajo?',
    content: `
      No, el centro de trabajo elige el área, oficina central, corporativo o los sitios que desee certificar. Incluso, es posible establecer el alcance
      de la certificación por tipo de personal, es decir, “administrativo”, de “base” o cualquier otra denominación con la que cuente el centro de trabajo.
      Se sugiere acercarse a los organismos de certificación para explorar las posibilidades que satisfagan los objetivos del centro de trabajo.
    `,
    subContents: []
});
