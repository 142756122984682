import { Content } from "src/app/models/content.model";

export const FAQ13: Content = new Content({
    idContent: 'c9c37cdc0c621bab3c051ddbfd778214',
    order: 13,
    title: '¿Qué será lo que el Organismo de Certificación revise en la auditoría de vigilancia?',
    content: `
        El Organismo de Certificación revisará que los centros de trabajo cumplan con el 100% de los
        puntos críticos. Además, los centros de trabajo deberán demostrar el mantenimiento del puntaje obtenido en la auditoría de certificación inicial como mínimo.
        En caso de no cumplir con ello se otorgará un periodo de 60 días hábiles
        para solventar lo necesario para dar cumplimiento; durante este periodo el certificado estará suspendido.
        En caso de no dar cumplimiento en tiempo y forma el certificado será cancelado.
        <br><br>
        En el proceso de auditoría de vigilancia se realizará la revisión de las medidas de nivelación, inclusión y acciones afirmativas sin condicionar la
        suspensión o cancelación del certificado, pero, en caso de incumplimiento de alguna medida, se cambiará el nivel de certificado.
    `,
    subContents: []
});
