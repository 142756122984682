import { Content } from 'src/app/models/content.model';

export const DESCRIPCION_DEL_TAMAÑO_DEL_SECTOR_PRODUCTIVO: Content = new Content({
   idContent: '5fa9e4daa585308fa94e4f499d323475',
   order: 1,
   title: 'Descripción del tamaño del sector productivo',
   content: `Si bien es cierto que cada empresa tiene unas características determinadas (tamaño, sector de actividad, servicios y público objetivo), y por lo tanto la estrategia concreta a seguir ha de adecuarse a ellas, la metodología que aquí se presenta ha de considerarse como un punto de referencia, una herramienta práctica, flexible y útil a las necesidades de cada organización.
   <br><br>
   En este sentido, conviene retomar lo estipulado en el Diario Oficial de la Federación en cuanto a la estratificación de los centros de trabajo en México:
   <br><br>
   <div class="row">
        <div class="col-12">
          <img src="../../../assets/infografias/INMUJERES_manual_b_graficos2.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </div>
  </div>
   `,
   subContents: []
});
