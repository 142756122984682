import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Este requisito se compone de diversos elementos, que deben ser cubiertos en su totalidad y de forma independiente para obtener un puntaje por cada uno de ellos.
   <br><br>
   Respecto del primer elemento, se debe demostrar con fotografías y con la visita del personal auditor, la existencia de un espacio privado, adecuado e higiénico para la lactancia materna, independientemente de que el personal que conforma el centro de trabajo sean mayormente hombres o de que en ese momento no existan mujeres que sean madres que puedan hacer uso de éste. Asimismo, se debe demostrar la promoción de la lactancia materna.
   <br><br>
   En el segundo elemento, se deberá mostrar de forma escrita las ofertas complementarias de cuidados de menores adicionales a las que proporciona el IMSS, el ISSSTE o el sistema de seguridad social con el que se cuente en el centro de trabajo, tales como convenios, ofertas o descuentos pactadas por el centro de trabajo con prestadores de servicios de cuidados de menores, con beneficios para la totalidad del personal que lo requiera en igualdad de condiciones.
   <br><br>
   En el tercer elemento se debe evidenciar la existencia de medidas para la flexibilización de los horarios mediante un informe de su uso, con información desagregada por sexo. Se entenderá como horario flexible aquel que permite que el personal tenga una hora de entrada y salida variable según sus necesidades, siempre y cuando se cumpla con la normatividad; y como horario escalonado a la existencia de horarios diferenciados preestablecidos, que están a disposición del personal. El teletrabajo consiste en la posibilidad de laborar de manera no presencial, utilizando medios informáticos en un lugar distinto a las instalaciones del centro de trabajo.
   <br><br>
   También, se entiende como semana de trabajo comprimida aquel esquema en que se cumple con la jornada laboral a la semana en menor número de días; mientras que las licencias y permisos con y sin goce de sueldo son aquellos mecanismos que involucran la ausencia de la persona trabajadora del centro de trabajo con la debida autorización.
   <br><br>
   Para el cumplimiento del cuarto elemento se deberá presentar un informe del uso de medidas de cuidados a dependientes, tomando en cuenta que para tales efectos no existe una definición única de diversidad de familias. A lo que se refiere este requisito es a que todo el personal tenga acceso a los mismos derechos y oportunidades independientemente del rol que desempeñen en su hogar o núcleo familiar, el cual incluye a aquellas personas que conviven allí, incluso sin tener una relación legal o de consanguineidad, así como a personas que viven solas.
   <br><br>
   Por último, el quinto elemento se evidencia con un documento que valida la difusión y otorgamiento de la licencia de paternidad con el pleno derecho que se le otorga al padre para el cuidado y atención de las hijas y los hijos en los primeros días de nacimiento, así como lo establece el artículo 132 de la Ley Federal del Trabajo Fracción XXVII Bis, refiriéndose al permiso de paternidad de al menos 5 días laborables con goce de sueldo para los hombres trabajadores. Cabe mencionar que si se otorgan los cinco días que señala la Ley, se obtendrán tres puntos y si se otorga al menos un día más se obtendrán cinco puntos. Además se requiere haber realizado la difusión correspondiente. 

   `,
   subContents: [
   ]
});