import { Doc } from "src/app/models/doc.model";

import { WHAT_IS_NORM_TOPIC } from "src/app/data/topics/que-es-la-norma/whatIsNorm.data";
import { COMO_PUEDO_CERTIFICAR_TOPIC } from 'src/app/data/topics/como-puedo-certificar/como-puedo-certificar.data';

import { FAQS } from "../topics/faqs.data";
import { FAQ1 } from "src/app/data/faqs/faq-1";
import { FAQ2 } from "src/app/data/faqs/faq-2";

import { DOCFILE1 } from "src/app/data/contents/docfile-igualdad.data";
import { DOCFILE2 } from "src/app/data/contents/docfile-instituto.data";
import { FAQ3 } from "../faqs/faq-3";
import { FAQ4 } from "../faqs/faq-4";
import { FAQ5 } from "../faqs/faq-5";

export const NORM_DOC: Doc = new Doc({
   idDoc: 'd4fdfb679d41495aaecbb0d71b381c17',
   name: 'Norma Mexicana NMX R 025 SCFI 2015 en Igualdad Laboral y No Discriminación',
   topics: [
      WHAT_IS_NORM_TOPIC,
      COMO_PUEDO_CERTIFICAR_TOPIC,
      FAQS
   ],
   faqs:[
      FAQ1,
      FAQ2,
      FAQ3,
      FAQ4,
      FAQ5
   ],
   docfiles:[
      DOCFILE1,
      DOCFILE2
   ]
});