import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Topic } from 'src/app/models/topic.model';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})

export class TopicsComponent implements OnInit {

  @Input() topics: Array<Topic> = [];
  @Input() accordion?: NgbAccordion;

  public idTopic: string = '';
  public idDocument: string = '';
  public idSubTopic: string = '';
  public idDetail: string = '';

  constructor(
    public activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.params.subscribe(params => {
      //console.log(params);
      
      this.idTopic = params['idTopic'];
      this.idDocument = params['idDoc'];
      this.idSubTopic = params['idSubTopic'] ?? '';
      this.idDetail = params['idDetail'] ?? '';

      if(this.idDetail) {
        this.idTopic = this.idDetail;
      } else if(this.idSubTopic) {
        this.idTopic = this.idSubTopic;
      }
    });
  
  }

  ngOnInit(): void {
    
  }

}
