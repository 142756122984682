import { Topic } from 'src/app/models/topic.model';
import { MEDIDA_1_DESCRIPCION } from '../../contents/medidas/medida-1/medida-1-descripcion.data';
import { MEDIDA_1_EVIDENCIAS } from '../../contents/medidas/medida-1/medida-1-evidencias.data';
import { MEDIDA_1_OBJETIVO } from '../../contents/medidas/medida-1/medida-1-objetivo.data';
import { MEDIDA_1_INTRO } from '../../contents/medidas/medida-1/medida-1-intro.data';
import { environment } from 'src/environments/environment';

export const MEDIDA_1: Topic = new Topic({
   idTopic: '79ca3592334b246b5f6a80e997cca164',
   order: 1,
   title: 'Medida 1: Integración de la plantilla',
   contents: [
    MEDIDA_1_INTRO,
    MEDIDA_1_OBJETIVO,
    MEDIDA_1_DESCRIPCION,
    MEDIDA_1_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.fourth
});
