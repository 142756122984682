import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Garantizar la igualdad salarial y otorgamiento de prestaciones y compensaciones al personal.
   <br><br>
   Asegurar que en el centro de trabajo no se discrimina en cuanto al salario, acceso y otorgamiento de prestaciones o beneficios agregados que recibe el personal, en correspondencia con la política en igualdad laboral y no discriminación.
   `,
   subContents: [
   ]
});