import { Topic } from 'src/app/models/topic.model';
import { INTRO_CONTENT } from 'src/app/data/contents/intro.data';
import { INFOGRAFIA_EJES } from '../../contents/intro/infografia-ejes.data';

import { environment } from 'src/environments/environment';

export const INTRO_TOPIC: Topic = new Topic({
   idTopic: '4616ec8c4a32955f617416ab69342399',
   order: 1,
   title: 'Introducción',
   subTopics: [],
   contents: [
      INFOGRAFIA_EJES,
      INTRO_CONTENT
   ],
   primaryColor: environment.colors.first
});
