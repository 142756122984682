import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55298/13.2_Accesibilidad_global_en_los_centros_de_trabajo_guia_buenas_practicas.pdf'"
               [title]="'Guía de buenas prácticas'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>

      <div class="col-12 row mb-4">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55302/13.3_Gu_a_de_aplicaci_n_t_cnica_de_accesibilidad.pdf'"
               [title]="'Guía de aplicación técnica proyecto Aptra'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>

      <div class="col-12 row">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.w3c.es/Divulgacion/GuiasBreves/Accesibilidad'"
               [title]="'Guía breve de accesibilidad web'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   `,
   subContents: [
   ]
});