import { Topic } from 'src/app/models/topic.model';
import { PADRON_DESCRIPCION } from './padron-descripcion.data';
import { PADRON_ENLACE } from './padron-enlace.data';
// import { PADRON_LISTADO } from './padron-listado.data';
import { environment } from 'src/environments/environment';

export const PADRON_NACIONAL: Topic = new Topic({
   idTopic: '8d19b02f4f8d66598c40adb9fcabb0fe',
   order: 1,
   title: 'Padrón Nacional de Centros de Trabajo Certificados',
   contents: [
    PADRON_DESCRIPCION,
    PADRON_ENLACE
   ],
   subTopics: [],
   primaryColor: environment.colors.fourth
});
