Si bien es cierto que cada empresa tiene unas características determinadas (tamaño, sector de actividad, servicios y público objetivo), y por lo tanto la estrategia concreta a seguir ha de adecuarse a ellas, la metodología que aquí se presenta ha de considerarse como un punto de referencia, una herramienta práctica, flexible y útil a las necesidades de cada organización.
<br><br>
En este sentido, conviene retomar lo estipulado en el Diario Oficial de la Federación en cuanto a la estratificación de las empresas en México:
<table class="table mt-4">
  <thead>
    <tr class="table-secondary">
      <th scope="col">Tamaño</th>
      <th scope="col">Sector productivo</th>
      <th scope="col">Cantidad de personal</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Micro</th>
      <td>Todo</td>
      <td>Hasta 10</td>
    </tr>
    <tr>
      <th 
        scope="row" 
        rowspan="2"
        class="align-middle">
        Pequeña
      </th>
      <td>Comercio</td>
      <td>Desde 11 hasta 30</td>
    </tr>
    <tr>
      <td>Industrial y servicios</td>
      <td>Desde 11 hasta 50</td>
    </tr>
    <tr>
      <th 
        scope="row" 
        rowspan="3"
        class="align-middle">
        Mediana
      </th>
      <td>Comercio</td>
      <td>Desde 31 hasta 100</td>
    </tr>
    <tr>
      <td>Servicios</td>
      <td>Desde 51 hasta 100</td>
    </tr>
    <tr>
      <td>Industria</td>
      <td>Desde 51 hasta 250</td>
    </tr>
    <tr>
      <th 
        scope="row"
        class="align-middle">
        Grande
      </th>
      <td colspan="2">
        El resto de las empresas que la Secretaría de Economía
        no considera dentro de los criterios para medir las 
        micro, pequeñas y medianas empresas.
      </td>
    </tr>
  </tbody>
</table>