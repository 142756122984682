import { Content } from "src/app/models/content.model";

export const FAQ14: Content = new Content({
    idContent: 'd02462990c12f979bff3a1c8b4f92791',
    order: 14,
    title: '¿Qué se necesita hacer para incrementar el nivel de certificación en la auditoría de vigilancia?',
    content: `
        El centro de trabajo deberá informar al Organismo de Certificación previa auditoría de vigilancia, que desea incrementar su nivel de certificación. Al momento de la auditoría, deberá
        presentar aquellas evidencias que acrediten el cumplimiento de las medidas de nivelación,
        medidas de inclusión y acciones afirmativas correspondientes.
        <br><br>
        Si fuera el caso de que efectivamente se incrementara el nivel de certificación, será responsabilidad del centro de trabajo dar aviso de dicha
        situación al Consejo Interinstitucional para que pueda realizarse la modificación pertinente en el Padrón Nacional de centros de trabajo certificados en la Norma Mexicana.
    `,
    subContents: []
});
