import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   <ul>
   
      <li class="mb-2">
         Documento que pruebe que los procesos de capacitación o adiestramiento (desde la convocatoria hasta su realización) son transparentes, accesibles y libres de sesgos sexistas o discriminatorios, y que consideran a todo el personal que labora en el centro de trabajo sin importar los niveles de responsabilidad.
      </li>

      <li class="mb-2">
         Documento que demuestre que se ha impartido capacitación o adiestramiento sin sesgos sexistas o discriminatorios, con desagregación por sexo.
      </li>

      <li class="mb-2">
         Prueba de la existencia de mecanismos transparentes, incluyentes y con perspectiva de género, para el acceso a la formación, capacitación y adiestramiento del personal durante la jornada de trabajo.
      </li>

      <li class="mb-2">
         Registro estadístico de participación.
      </li>

      <li class="mb-2">
         Listas de asistencia desagregadas por sexo.
      </li>

      <li class="mb-2">
         Documentos, imágenes, fotografías o constancias que demuestren la participación del personal.
      </li>

      <li class="mb-2">
         Documentos, imágenes, fotografías o constancias que demuestren la difusión con accesibilidad para todo el personal.
      </li>

   </ul>
   `,
   subContents: [
   ]
});