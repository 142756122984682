import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   Como evidencia del cumplimiento de la realización
   de la auditoría interna se deberá entregar
   un informe, suscrito por la máxima autoridad,
   alta dirección o persona representante legal del
   centro de trabajo, y que cumpla con lo siguiente:
   <br><br>
   <ul>
      <li class="mb-2">
         Contemplar los 14 requisitos establecidos en la Norma Mexicana, incluyendo la aplicación del requisito 5.
      </li>
      <li class="mb-2">
         La mejora de la eficacia de las prácticas de igualdad y no discriminación. La eficacia se mide en el grado de cumplimiento del requisito auditado, aunado a las acciones correctivas que permiten subsanar las no conformidades de la auditoría interna y diagnóstico de autoevaluación, según sea el caso. Esto puede reflejarse en los planes de acción del requisito 1.
      </li>
      <li class="mb-2">
         Definir las acciones y los recursos necesarios para la implementación de prácticas y acciones de igualdad laboral y no discriminación en el centro de trabajo. Esto puede reflejarse en los planes de acción del requisito 1.
      </li>
      <li class="mb-2">
         Planes de acción ante cualquier desviación detectada durante la auditoría, es decir una no conformidad. Pueden ser los mismos planes de acción del requisito 1.
      </li>
   </ul>
   `,
   subContents: [
   ]
});