import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55133/2.1_Formato_de_acta_de_instalaci_n_del_Grupo__Comisi_n_o_Comit_.pdf'"
               [title]="'Formato de instalación del grupo, comisión o comité'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>

      <div class="col-12 row">
         <div class="w-auto mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55283/2.2_Ejemplo_de_lineamientos_de_operaci_n_del_Grupo__Comisi_n_o_Comit_.pdf'"
               [title]="'Lineamientos de operación del grupo, comisión o comité'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>

   `,
   subContents: [
   ]
});