import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   El centro de trabajo deberá generar como evidencias documentos probatorios para la identificación de la igualdad salarial:
   <br><br>
   <ul>
      <li class="mb-2">
         Manuales, documentos de operación, procedimientos, lineamientos o guías para la asignación de compensaciones, basados en la evaluación del desempeño, que asimismo:
      </li>
      <ul>
         <li class="mb-2">
            Listado de puestos por categorías, por salarios y por sexo.
         </li>
      </ul>
   </ul>
   `,
   subContents: [
   ]
});