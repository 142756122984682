import { Content } from "src/app/models/content.model";

export const FAQ12: Content = new Content({
    idContent: 'a443cc44b0a3c95e206b735346173bf3',
    order: 12,
    title: '¿El mismo Organismo de Certificación que realizó la auditoría inicial es el que realiza la auditoría de vigilancia?',
    content: `
      Si bien la Norma no establece que el Organismo de Certificación que llevó a cabo la auditoría de certificación del centro de trabajo deba
      ser el mismo que realice la auditoría de vigilancia, las entidades correspondientes sí lo han determinado así.
    `,
    subContents: []
});
