import { Content } from 'src/app/models/content.model';

export const MEDIDA_1_EVIDENCIAS: Content = new Content({
   idContent: 'd00c883978f0eb5fdef52efd38472858',
   order: 1,
   title: 'Conformación de evidencias',
   content: `
    El centro de trabajo deberá generar como evidencias:
    <br><br>
    <ul>
      <li>Documento que contenga la plantilla de personal en el que se demuestre que existe al menos el 40% de mujeres, para lo que es necesario que dicha plantilla cuente con una sección en la que se desagregue el sexo de las personas contratadas.</li>
      <li>Documento en el que se señalen las acciones realizadas en torno al reclutamiento y promoción para incrementar la integración de mujeres a la plantilla de personal.</li>
    </ul>
   `,
   subContents: []
});
