import { Content } from 'src/app/models/content.model';

export const PROOFS_CONTENT: Content = new Content({
   idContent: 'ee8d9e25b2cc54b306df1e1802320034',
   order: 1,
   title: 'Conformación de las evidencias',
   content: `
   En la conformación e instalación de un grupo,
   comisión o comité, el centro de trabajo deberá
   generar como evidencias las siguientes:
   <br><br>
   <ul>
      <li class="mb-2">
         Acta de instalación, suscrita por la máxima autoridad, alta dirección o representante legal del centro de trabajo, en la que sea visible que el grupo, comisión o comité está integrado equitativamente por mujeres y hombres de manera proporcional respecto a la población total del alcance, que provienen de diversas áreas de responsabilidad, en la cual se definan las responsabilidades, funciones y grado de autoridad, es decir, el cargo y funciones de cada miembro del grupo, comisión o comité.
      </li>
      <li class="mb-2">
         Lineamientos para la operación con un código de conducta que asegure la objetividad, imparcialidad y confidencialidad. Es posible que el grupo, comisión o comité se rija por el código de conducta existente en la organización, siempre y cuando se haga referencia a él dentro de las evidencias mostradas y dicho código asegure la objetividad, imparcialidad y confidencialidad en el comportamiento.
      </li>
   </ul>
   `,
   subContents: [
   ]
});