import { Content } from 'src/app/models/content.model';

export const PADRON_LISTADO: Content = new Content({
   idContent: '8e7baaee9846b7bb269b80860465e20b',
   order: 1,
   title: 'Listado de organismos de certificación acreditados',
   content: `
    Listado alfabético de organismos de certificación acreditados para la evaluación de la conformidad de la Norma Mexicana
    <br>
    <font size="2">
        <table class="table mt-4">
            <thead>
                <tr class="table-secondary">
                    <th scope="col" colspan="5" class="text-center">DATOS DE CONTACTO</th>
                </tr>
            </thead>
            <thead>
                <tr class="table-secondary">
                    <th scope="col" width="10px">No.</th>
                    <th scope="col">Nombre del Organismo de Certificación</th>
                    <th scope="col">Contacto</th>
                    <th scope="col">Correo electrónico</th>
                    <th scope="col" width="120px">Teléfono</th>
                </tr>
            </thead>
            <tbody>         
                <tr>    
                    <th>1</th>
                    <td>ACCM América, S. de R.L. de C.V.</td>
                    <td>Dr. Luis Mauricio Cárdenas Flores</td>
                    <td>direccion@accm.com.mx</td>
                    <td>
                        55-90-00-49-32<br>
                        55-90-00-49-33<br>
                        Ext. 115<br>
                    </td>
                </tr>
                <tr>    
                    <th>2</th>
                    <td>Asociación de Normalización y Certificación, A.C.</td>
                    <td>Lic. Jessica Celis Téllez</td>
                    <td>jessica.celis@ance.org.mx</td>
                    <td>
                        55-57-47-45-50<br>
                    </td>
                </tr>
                <tr>    
                    <th>3</th>
                    <td>Auditores Asociados de México, S.C.</td>
                    <td>Ing. Luz Adriana Rodríguez Hernández</td>
                    <td>gerencia.igualdad@audamex.com</td>
                    <td>
                        55-52-35-65-44<br>
                        55-52-35-65-45<br>
                    </td>
                </tr>
                <tr>    
                    <th>4</th>
                    <td>BAS International Certification Co. S.C.</td>
                    <td>Mtra. Martha Elba Baeza Guerrero</td>
                    <td>direccion@basicco.org.mx</td>
                    <td>
                        55-55-11-28-11<br>
                        55-55-33-68-83<br>
                    </td>
                </tr>
                <tr>    
                    <th>5</th>
                    <td>Certificación Mexicana, S.C.</td>
                    <td>Ing. Roberto Vargas Soto</td>
                    <td>rvargas@cmx.org.mx</td>
                    <td>
                        55-26-43-12-12<br>
                        55-26-43-15-81<br>
                        Ext. 105 y 108<br>
                    </td>
                </tr>
                <tr>    
                    <th>6</th>
                    <td>Factual Services, S.C.</td>
                    <td>Ing. Araceli González Morales</td>
                    <td>agonzalez@factualservices.com</td>
                    <td>
                        55-53-40-88-70<br>
                        55-53-40-88-71<br>
                        Ext. 5101<br>
                    </td>
                </tr>
                <tr>    
                    <th>7</th>
                    <td>Instituto Mexicano de Normalización y Certificación, A.C.</td>
                    <td>
                        Lic. Fernando Solís Mata<br>
                        Lic. Ana Laura Florencio<br>
                    </td>
                    <td>
                        fsolis@imnc.org.mx<br>
                        aflorencio@imnc.org.mx<br>
                    </td>
                    <td>
                        55-55-46-45-46<br>
                        3106 y 3101<br>
                    </td>
                </tr>
                <tr>    
                    <th>8</th>
                    <td>Maxan Certificación S.A. de C.V.</td>
                    <td>Lic. Irma Yolanda Hernández Vilchis</td>
                    <td>direccioncomercial@maxan.com.mx</td>
                    <td>
                        72 29 17 76 33<br>
                        Ext. 102 y 103<br>
                    </td>
                </tr>
                <tr>    
                    <th>9</th>
                    <td>Normalización y Certificación NYCE, S.C.</td>
                    <td>Mtra. Laura Campos Llop</td>
                    <td>lcampos@nyce.org.mx</td>
                    <td>
                        55-53-95-07-77<br>
                        Ext. 402<br>
                    </td>
                </tr>
                <tr>    
                    <th>10</th>
                    <td>Verificadores Internacionales Mexicanos S.A. de C.V.</td>
                    <td>
                        Ing. Rocío Mendoza M.<br>
                        Ing. Edgar I. Pérez Santiago<br>
                    </td>
                    <td>
                        rmendoza@evaluacionesvim.com<br>
                        eperez@evaluacionesvim.com<br>
                    </td>
                    <td>
                        55-70-30-38-46<br>
                    </td>
                </tr>
            </tbody>
        </table>
    </font>
    
   `,
   subContents: []
});