import { Content } from 'src/app/models/content.model';

import { DOCUMENTO_NORMA_CONTENT } from './intro/documento-norma.data';
import { DATOS_CONTACTO } from './intro/datos-contacto.data';

export const INTRO_CONTENT: Content = new Content({
   idContent: '30ee17d46edcde7d28e3359cdb85244f',
   order: 1,
   title: 'Norma Mexicana NMX R 025 SCFI 2015 en Igualdad Laboral y No Discriminación',
   content: `La Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación (más adelante se nombrará únicamente Norma Mexicana) es un mecanismo de adopción voluntaria para reconocer a los centros de trabajo que cuentan con prácticas en materia de igualdad laboral y no discriminación, para favorecer el desarrollo integral de las y los trabajadores.
   <br><br>
   Esta certificación está dirigida a todos los centros de trabajo públicos, privados y sociales establecidos en la República Mexicana, de cualquier tamaño, sector o actividad. Para obtenerlo, los centros de trabajo deberán recibir una auditoría de tercera parte, para verificar que sus políticas y prácticas cumplen con los requisitos de igualdad laboral y no discriminación.
   <br><br>
   Sus principales ejes son:
   <ol>
      <li>Incorporar la perspectiva de género y no discriminación en los procesos de reclutamiento, selección, movilidad y capacitación.</li>
      <li>Impulsar la igualdad salarial.</li>
      <li>Implementar acciones para prevenir y atender la violencia laboral.</li>
      <li>Realizar acciones de corresponsabilidad entre la vida laboral, familiar y personal de sus trabajadoras y trabajadores, con igualdad de trato y de oportunidades.</li>
   </ol>
   `,
   subContents: [
      DATOS_CONTACTO,
      DOCUMENTO_NORMA_CONTENT
   ]
});
