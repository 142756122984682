<div class="container-fluid" *ngIf="document">

   <div class="row">

      <div 
         class="col-12 col-md-5 col-lg-3 row d-none d-md-block"
         [ngStyle]="{
            backgroundColor: 'rgb(242, 242, 247)'
         }">
         <app-topics
            class="col-12"
            [topics]="document!.topics"></app-topics>
      </div>

      <div class="col-12 col-md-7 col-lg-6 px-4 px-md-5 pt-0 pt-md-4">
         <div class="row">

            <app-menu-index
               class="d-block d-md-none mb-4"
               [topics]="document!.topics"></app-menu-index>

            <app-content
               class="col-12 mb-5 p-0"
               [topic]="currentTopic!"></app-content>
            <app-pagination
               [document]="document"
               [currentTopic]="idTopic"
               [currentSubtopic]="idSubTopic"
               [currentDetail]="idDetail"
               class="col-12 mb-5"></app-pagination>

         </div>
      </div>

      <app-contents
            [contents]="currentTopic!.contents"
            class="col-3 d-none d-lg-block pt-4"></app-contents>

   </div>

</div>