import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   Manual para el uso no sexista del lenguaje
   <br><br>
   <div class="row">
      <div class="col-12 row">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55295/11.1_Manual_para_el_uso_no_sexista_del_lenguaje__2011.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   <br>
   Diez recomendaciones para el uso no sexista del lenguaje.
   <br><br>
   <div class="row">
      <div class="col-12 row">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55297/11.2_Diez_recomendaciones_para_el_uso_no_sexista_del_lenguaje__2009.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   <br>
   Manual de comunicación no sexista. Hacia un lenguaje incluyente.
   <br><br>
   <div class="row">
      <div class="col-12 row">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://cedoc.inmujeres.gob.mx/documentos_download/101265.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   `,
   subContents: [
   ]
});