import { Topic } from 'src/app/models/topic.model';

import { MEDIDA_1 } from './medida1.data';
import { MEDIDA_2 } from './medida2.data';
import { MEDIDA_3 } from './medida3.data';
import { MEDIDA_4 } from './medida4.data';
import { MEDIDA_5 } from './medida5.data';
import { MEDIDAS_DESCRIPCION } from './medidas-descripcion.data';
import { environment } from 'src/environments/environment';

export const MEDIDAS_NIVELACION: Topic = new Topic({
   idTopic: 'c82d68dfa1a220f576bbae7b7500be33',
   order: 1,
   title: 'Medidas de nivelación',
   subTopics: [
    MEDIDA_1,
    MEDIDA_2,
    MEDIDA_3,
    MEDIDA_4,
    MEDIDA_5
   ],
   contents: [
      MEDIDAS_DESCRIPCION
   ],
   primaryColor: environment.colors.third
});