import { Topic } from 'src/app/models/topic.model';

import { WHO_CAN_BE_CERTIFIED_CONTENT } from 'src/app/data/contents/whoCanBeCertified.data';
import { INFOGRAFIA_QUIENES_PUEDEN_CERTIFICARSE } from './infografia-quienes-pueden-certificarse.data';
import { environment } from 'src/environments/environment';

export const WHO_CAN_BE_CERTIFIED_TOPIC: Topic = new Topic({
   idTopic: '29c79e8bfa8db5ff411332b5ead951b3',
   order: 2,
   title: '¿Quiénes pueden certificarse?',
   contents: [
      INFOGRAFIA_QUIENES_PUEDEN_CERTIFICARSE,
      WHO_CAN_BE_CERTIFIED_CONTENT
   ],
   subTopics: [],
   primaryColor: environment.colors.third
});
