import { Content } from 'src/app/models/content.model';

export const MEDIDA_5_INTRO: Content = new Content({
   idContent: 'e2fd8eb88b28cc9df366b7d5d5c19c14',
   order: 1,
   title: '',
   content: `
    Llevar a cabo actividades o eventos enfocados exclusivamente a fomentar la igualdad y no discriminación y que sean dirigidos al personal del centro de trabajo y sus familias.
   `,
   subContents: []
});
