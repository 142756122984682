import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Contar con procesos de formación, capacitación, adiestramiento y con igualdad de oportunidades.
   <br><br>
   Asegurar que los procesos de formación, capacitación, adiestramiento se den con igualdad de oportunidades y sin ningún tipo de discriminación para todo el personal, en correspondencia con la política en igualdad laboral y no discriminación
   `,
   subContents: [
   ]
});