import { Topic } from 'src/app/models/topic.model';

import { OBJECT_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-9/objetivo.data';
import { DESCRIPTION_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-9/description.data';
import { PROOFS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-9/evidencias.data';
import { TOOLS_CONTENT } from 'src/app/data/contents/como-certificar/requisitos-certificacion/requisito-9/herramientas.data';
import { REQUISITO_NO_CRITICO } from 'src/app/data/contents/como-certificar/requisitos-certificacion/no-critico.data';
import { environment } from 'src/environments/environment';

export const REQUIREMENT_9_TOPIC: Topic = new Topic({
   idTopic: 'ff76d7e2ebb18348923ec6521d92915f',
   order: 1,
   title: 'Requisito 9: Proceso de capacitación',
   subTopics: [
      
   ],
   contents: [
      REQUISITO_NO_CRITICO,
      OBJECT_CONTENT,
      DESCRIPTION_CONTENT,
      PROOFS_CONTENT,
      TOOLS_CONTENT
   ],
   primaryColor: environment.colors.third
});