import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   Todos los elementos de este requisito deberán considerarse para su cumplimiento. En este sentido, es relevante señalar que la evaluación del desempeño para la asignación de sueldos y salarios es fundamental, así como contar con un listado de puestos, categorías, y salarios por sexo en el que se pueda evidenciar que a puestos iguales se obtiene el mismo salario sin diferencias basadas en características discriminatorias como el sexo.
   <br><br>
   Deberán presentarse evidencias para cada requerimiento delimitado en el cuerpo de los elementos, es decir, deberá realizarse un ejercicio de revisión oportuno para el cumplimiento adecuado de lo solicitado.

   `,
   subContents: [
   ]
});