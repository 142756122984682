import { Content } from 'src/app/models/content.model';

export const DATOS_CONTACTO: Content = new Content({
   idContent: '14f22fd4263feb3339272a16126b31f3',
   order: 1,
   title: 'Datos de contacto',
   content: `
        <!-- César Hernández Retama<br>
        Jefe de departamento de Igualdad Laboral y la No Discriminación
        <br><br> -->
        Barranca del Muerto No. 209 Piso 8, Col. San José Insurgentes, Alcaldía Benito Juárez, Ciudad de México, C.P. 03900.<br>
        Teléfono: (55) 5322 4200 ext. 2401, 2403 y 2408
        <br><br>
        Dirección General de la Política Nacional de Igualdad y Derechos de las Mujeres<br>
        Dirección para la Igualdad de Mujeres y Hombres en la Vida Económica<br>
   `,
   subContents: []
});