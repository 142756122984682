import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   Proceso de formación, capacitación, adiestramiento con igualdad de oportunidades
   <br><br>
   <div class="row">
      <div class="col-12 row mb-4">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55290/9.1_Ejemplo_de_proceso_de_formaci_n_capacitaci_n.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   <br>
   Programa anual de formación, capacitación con igualdad de oportunidades.
   <br><br>
   <div class="row">
      <div class="col-12 row">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55292/9.2_Elementos_del_programa_anual_de_formaci_n_capacitaci_n.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>

   `,
   subContents: [
   ]
});