import { Content } from 'src/app/models/content.model';

export const OBJECT_CONTENT: Content = new Content({
   idContent: '8a9f6f925ea12977a099010f44cc47bc',
   order: 1,
   title: 'Objetivo',
   content: `
   Utilizar lenguaje incluyente, no sexista y accesible.
   <br><br>
   Eliminar los sesgos de discriminación en el lenguaje oral y escrito, de modo que se reconozca a todas las personas por igual, manifestando la diversidad social, y contribuir a la existencia de un lenguaje adecuado para que todas las personas se sientan incluidas en la cultura organizacional.
   `,
   subContents: [
   ]
});