import { Topic } from 'src/app/models/topic.model';
import { MEDIDA_5_DESCRIPCION } from '../../contents/medidas/medida-5/medida-5-descripcion.data';
import { MEDIDA_5_EVIDENCIAS } from '../../contents/medidas/medida-5/medida-5-evidencias.data';
import { MEDIDA_5_OBJETIVO } from '../../contents/medidas/medida-5/medida-5-objetivo.data';
import { MEDIDA_5_INTRO } from '../../contents/medidas/medida-5/medida-5-intro.data';
import { environment } from 'src/environments/environment';

export const MEDIDA_5: Topic = new Topic({
   idTopic: '8425b8ff06d725daa6359b6178b6c84c',
   order: 5,
   title: 'Medida 5: Eventos para fomentar la igualdad',
   contents: [
    MEDIDA_5_INTRO,
    MEDIDA_5_OBJETIVO,
    MEDIDA_5_DESCRIPCION,
    MEDIDA_5_EVIDENCIAS
   ],
   subTopics: [],
   primaryColor: environment.colors.second
});
