import { Content } from 'src/app/models/content.model';

export const DESCRIPTION_CONTENT: Content = new Content({
   idContent: '79f5ae8108e9957a662f3dc38bd49a5b',
   order: 1,
   title: 'Descripción',
   content: `
   El mecanismo o regulación debe evidenciarse con un documento que contenga el protocolo, código, lineamiento, procedimiento o disposición de otro tipo, que debe estar acorde con la política de igualdad laboral y no discriminación del centro de trabajo, sin embargo, uno no puede suplir al otro.
   <br><br>
   Asimismo, debe contener las acciones que se llevarán a cabo para la prevención de prácticas de discriminación y violencia laboral fundada en prejuicios y estigmas discriminatorios, además de estrategias de atención, sanciones e información de contacto o rutas para ejercer la canalización a otras vías de acción, como la laboral o penal. 
   <br><br>
   Adicionalmente al mecanismo, se deben presentar los informes del uso del mismo, es decir, número de casos, si se resolvieron o no y de qué forma, así como la información desagregada por sexo del personal que ha hecho uso del mecanismo (no es necesario que se especifiquen nombres y detalles de cada caso atendido). Además se deberán presentar informes de ejecución de acciones de prevención de las prácticas de discriminación y violencia laboral desagregadas por sexo.
   <br><br>
   En caso de no existir denuncia alguna se debe presentar un informe que dé cuenta de ello, el cual debe estar firmado por la autoridad competente, entendiendo a ésta como la persona que el propio centro de trabajo ha nombrado como responsable del mecanismo o regulación.
   <br><br>
   Para la administración pública federal, el Protocolo para la prevención, atención y sanción del hostigamiento sexual y acoso sexual publicado en el Diario Oficial de la Federación el 31 de agosto de 2016 por la Secretaría de Gobernación, la Secretaría de la Función Pública y el Instituto Nacional de las Mujeres, puede formar parte de este requisito, pero no lo sustituye. Dicho protocolo abarca el hostigamiento sexual y el acoso sexual, que son formas de violencia laboral, pero no incluye otras manifestaciones de ella, ni tampoco incluye los actos de discriminación. Para dar cumplimiento a la norma, los mecanismos y regulaciones que se implementen deben prevenir, atender y sancionar tanto las prácticas de discriminación como la violencia laboral en el centro de trabajo.
   `,
   subContents: [
   ]
});