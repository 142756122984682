
<div 
   *ngIf="content!.title != ''"
   class="col-12 pe-0">
   <div class="row">

      <div 
         class="col-12 pe-0 d-flex align-items-center"
         [ngClass]="{
            'title font-weight-700': sublevel == 0,
            'title-1 font-weight-500': sublevel == 1,
            'title-2 font-weight-400': sublevel == 2,
            'mb-2': content?.getLetter(index) != ''
         }">
         <div class="row">

            <div 
               *ngIf="content?.kindOfContent == 'subsection'"
               class="col-1 text-white text-center rounded-circle d-flex align-items-center justify-content-center"
               style="width: 2em; height: 2em"
               [ngStyle]="{
                  backgroundColor: primaryColor
               }">
               <span>{{ content?.getLetter(index) }}</span>
            </div>
            <div
               *ngIf="content?.kindOfContent != 'subsection'"
               class="col"
               [ngStyle]="{
                  color: primaryColor
               }">
               {{ content!.title }}
            </div>

            <div
               *ngIf="content?.kindOfContent == 'subsection'"
               class="col">
               {{ content!.title }}
            </div>

         </div>
      </div>
      <div class="col-12 mb-4 pe-0">
         <hr class="">
      </div>

   </div>
</div>

<!-- <div class="col-12 text-justify"
   [innerHTML]="content!.content | sanitizedHtml ">
</div> -->

<div class="col-12 text-justify">
   <ngx-dynamic-hooks [content]="content!.content"></ngx-dynamic-hooks>
</div>

<app-item-content
   class="col-12 row my-4 pe-0"
   *ngFor="let subContent of content!.subContents; let i = index"
   [sublevel]="sublevel + 1"
   [index]="i"
   [content]="subContent"
   [primaryColor]="primaryColor"></app-item-content>