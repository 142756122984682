import { Component, Input } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-acordition',
  templateUrl: './acordition.component.html',
  styleUrls: ['./acordition.component.scss']
})
export class AcorditionComponent {
  activeFaqID: string = '';
  idDoc: string = '';
  title: string = 'Preguntas frecuentes';
  faqDoc;

  constructor(
    public documentService: DocumentService,
  ) {
    this.activeFaqID = documentService.docs[0].faqs[0].idContent;
    this.idDoc = documentService.docs[0].idDoc;
    this.faqDoc =  documentService.docs[0].topics.find(element => (element.title == this.title && element.order > 1));
  }

  ngOnInit(): void {
  }

}
