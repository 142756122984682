import { Content } from 'src/app/models/content.model';

export const MEDIDA_4_OBJETIVO: Content = new Content({
   idContent: '9b03164ddd944008b6f38fce9058f8db',
   order: 1,
   title: 'Objetivo',
   content: `
    Contar con una figura mediadora encargada de la protección de los derechos de las personas trabajadoras en sus contactos con las autoridades del centro de trabajo.
   `,
   subContents: []
});
