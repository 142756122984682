import { Component, OnInit, Input } from '@angular/core';

import { Topic } from 'src/app/models/topic.model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})

export class ContentComponent implements OnInit {

  @Input() topic!: Topic;
  
  ngOnInit(): void {
    
  }

}
