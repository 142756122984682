export interface IPaginationItem {
   url: string;
   title: string;
   subTitle: string;
}

export class PaginationItem {
   url: string;
   title: string;
   subTitle: string;

   constructor(
      data: IPaginationItem
   ) {
      this.url = data.url;
      this.title = data.title;
      this.subTitle = data.subTitle;

   }
}
