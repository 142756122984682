import { Content } from 'src/app/models/content.model';


export const REQUISITOS_CRITICOS_CONTENT: Content = new Content({
   idContent: 'ecbee800dfe6a5978aa53bdcf96b66d9',
   order: 1,
   title: 'Requisitos de certificación o  críticos',
   content: `
   Son aquellos cuya evaluación de la conformidad requiere que los centros de trabajo demuestren el trabajo sustantivo en los temas que se incluyen en la Norma Mexicana. Permiten al centro de trabajo demostrar la implementación de políticas y acciones encaminadas a conseguir la igualdad laboral y la no discriminación en sus actividades diarias. 
   <br><br>
   Su cumplimiento es indispensable para obtener la certificación, tienen un valor de 25 puntos.
   <br><br>
   <ul>
      <li>Política de igualdad laboral</li>
      <li>Comité de vigilancia</li>
      <li>Proceso de reclutamiento y selección</li>
      <li>Auditoría interna</li>
      <li>Clima laboral</li>
      <li>Código de ética</li>
   </ul>
   `,
   subContents: [
      
   ]
});