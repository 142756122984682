import { Content } from 'src/app/models/content.model';

export const PADRON_CONTENT: Content = new Content({
   idContent: '92a0dcef150e046c9d0401174def5015',
   order: 1,
   title: '',
   content: `
      El centro de trabajo podrá consultar en la página de la Entidad Mexicana de Acreditación (EMA) el listado de organismos de certificación
      avalados para certificar en la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación, con los cuales podrá entrar en
      contacto para solicitar una cotización del servicio para la evaluación de la conformidad.
      <br></br>
      Además de los datos de contacto, el organismo de certificación solicitará información para determinar el costo de la auditoría, tales como el
      alcance de la certificación y la ubicación geográfica del o los sitios a auditar, así como el número total de personas que laboran en el o los
      sitios a auditar.
      <br></br>
      Si un centro de trabajo desea ser auditado bajo el esquema multisitios, deberá garantizar que todos los procesos y buenas prácticas evaluados en la norma se aplican en cada una de las
      sedes que plantea en el alcance. El organismo de certificación informará al centro de trabajo si cumple con los criterios señalados en la norma para ser tomado en cuenta como tal.
      <br></br>
      Una vez que se haya seleccionado al organismo de certificación de su preferencia, deberá suscribirse un contrato de servicio con el mismo, donde se detallen los rubros del proceso
      de certificación y el número de sitios a visitar, la fecha de realización de la auditoría, las vías
      de comunicación y confirmación de la misma, entre otros.
   `,
   subContents: []
});
