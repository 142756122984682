<nav class="navbar navbar-expand-md navbar-light nav-collapsed">
	<div class="container-fluid">
        <a href="/" class="image-container">
            <img [src]="logoInMujeres" 
                 alt="MDN"
                 width="80"
                 height="50">
        </a>
		<button
			class="navbar-toggler"
			type="button"
			aria-controls="navbarContent"
			[attr.aria-expanded]="!collapsed"
			aria-label="Toggle navigation"
			(click)="collapsed = !collapsed"
		>
			<span class="navbar-toggler-icon"></span>
		</button>

		<div class="navbar-collapse collapse-items" [class.collapse]="collapsed" id="navbarContent">
			<ul class="navbar-nav ms-auto no-bullets">
				<li class="nav-item" ngbDropdown placement="bottom-end">
					<a class="color-menu" href="/" tabindex="0">Inicio</a>
					<a class="color-menu" tabindex="1" ngbDropdownToggle id="navbarDropdown1" role="button">Introducción</a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
						<a 
							[routerLink]="['/docs', idDoc, 'topics', item.idTopic]"
							*ngFor="let item of documentService.docs[0].topics" 
							ngbDropdownItem 
							(click)="$event.preventDefault()">
							{{item.title}}
						</a>
					</div>
				</li>
			</ul>
		</div>
        
	</div>
</nav>