export interface IBanner {
    idBanner: string;
    order: number;
    title: string;
    content: string;
    url: string;
 }
 
 export class Banner {
    idBanner: string;
    order: number;
    title: string;
    content: string;
    url: string;
 
    constructor(data: IBanner) {
       this.idBanner = data.idBanner;
       this.order = data.order;
       this.title = data.title;
       this.content = data.content;
       this.url = data.url;
    }
 }