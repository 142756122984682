import { Topic } from 'src/app/models/topic.model';

import { REQUIREMENT_1_TOPIC } from './requisito-1.data';
import { REQUIREMENT_2_TOPIC } from './requisito-2.data';
import { REQUIREMENT_3_TOPIC } from './requisito-3.data';
import { REQUIREMENT_4_TOPIC } from './requisito-4.data';
import { REQUIREMENT_5_TOPIC } from './requisito-5.data';
import { REQUIREMENT_6_TOPIC } from './requisito-6.data';
import { REQUIREMENT_7_TOPIC } from './requisito-7.data';
import { REQUIREMENT_8_TOPIC } from './requisito-8.data';
import { REQUIREMENT_9_TOPIC } from './requisito-9.data';
import { REQUIREMENT_10_TOPIC } from './requisito-10.data';
import { REQUIREMENT_11_TOPIC } from './requisito-11.data';
import { REQUIREMENT_12_TOPIC } from './requisito-12.data';
import { REQUIREMENT_13_TOPIC } from './requisito-13.data';
import { REQUIREMENT_14_TOPIC } from './requisito-14.data';
import { REQUISITOS_DESCRIPCION } from './requisitos-descripcion.data';
import { environment } from 'src/environments/environment';

export const REQUISITOS_CERTIFICACION_TOPIC: Topic = new Topic({
   idTopic: '352b3f4721f91c2f86cde1abe44fd356',
   order: 1,
   title: 'Requisitos de certificación',
   subTopics: [
      REQUIREMENT_1_TOPIC,
      REQUIREMENT_2_TOPIC,
      REQUIREMENT_3_TOPIC,
      REQUIREMENT_4_TOPIC,
      REQUIREMENT_5_TOPIC,
      REQUIREMENT_6_TOPIC,
      REQUIREMENT_7_TOPIC,
      REQUIREMENT_8_TOPIC,
      REQUIREMENT_9_TOPIC,
      REQUIREMENT_10_TOPIC,
      REQUIREMENT_11_TOPIC,
      REQUIREMENT_12_TOPIC,
      REQUIREMENT_13_TOPIC,
      REQUIREMENT_14_TOPIC,
   ],
   contents: [
      REQUISITOS_DESCRIPCION
   ],
   primaryColor: environment.colors.sixth
});