import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoNumberChar'
})
export class TwoNumberCharPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let newValue = value.toString();

    switch (newValue.length) {
      case 0:
        newValue = `00`;
        break;
      
      case 1:
        newValue = `0${value}`;
        break;
    
      default:
        newValue = newValue;
        break;
    }
    return newValue;
  }

}
