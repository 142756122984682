<div class="col-12">
    <div class="col-12 row">
        <div class="col-12 title font-weight-700">
            <span class="me-2">
                Preguntas frecuentes
            </span>
            <a 
                [routerLink]="['/docs', idDoc, 'topics', faqDoc?.idTopic]"
                class="text-decoration-none text-fourth button-faq"
                style="font-size: 0.8rem">
                Ver más
            </a>
         </div>
        <div class="col-12 mb-5">
           <hr class="with-decoration">
        </div>
    </div>
    <ngb-accordion [closeOthers]="true" activeIds="{{activeFaqID}}">
        <ngb-panel 
            *ngFor="let faq of documentService.docs[0].faqs" 
            id="{{faq.idContent}}"
            cardClass="border-0 bg-white text-black">
            <ng-template ngbPanelTitle>
                <h5 class="font-weight-600">
                    {{ faq.title }}
                </h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <p class="font-weight-300 text-justify">
                    {{faq.content}}
                </p>
            </ng-template>
        </ngb-panel>

    </ngb-accordion>
</div>
