import { Content } from 'src/app/models/content.model';

export const MEDIDAS_DESCRIPCION: Content = new Content({
   idContent: '704f9f9d636719066e2eea38075d24aa',
   order: 1,
   title: '',
   content: `
      Son medidas que buscan hacer efectivo el acceso de todas las personas a la igualdad realde oportunidades eliminando las barreras físicas, comunicacionales, normativas o de otro tipo, que obstaculizan el ejercicio de derechos y libertades prioritariamente a las mujeres y a los grupos en situación de discriminación o vulnerabilidad.
      <br><br>
      Las medidas de nivelación son:
      <div class="row">
        <div class="col-12">
          <img src="../../../assets/infografias/inmujeres-manual-b-graficos5.png"
            alt="MDN"
            class="rounded mx-auto d-block"
            width="100%">
        </div>
      </div>
    `,
   subContents: []
});
