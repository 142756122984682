<div 
   class="col-12">
   <div class="row">

      <div class="col-12 title font-weight-700">
         {{ doc!.name }}
      </div>
      <div class="col-12">
         <hr class="with-decoration">
      </div>
      <div class="col-12 d-flex justify-content-center pt-3">
   
         <div class="row">

            <app-topic-item
            class="col-12 col-md-6 col-lg-3 p-md-0 mb-3 mb-lg-0"
            [idDoc]="doc?.idDoc!"></app-topic-item>
   
            <app-topic-item 
               class="col-12 col-md-6 col-lg-3 p-md-0 mb-3 mb-lg-0"
               *ngFor="let topic of doc!.topics; let i = index"
               [idDoc]="doc?.idDoc!"
               [index]="i + 1"
               [topic]="topic">
            </app-topic-item>

         </div>
   
      </div>

   </div>
</div>