<div 
   class="row p-0 p-md-4">
   <a 
      [routerLink]="['/docs', idDoc, 'topics', topic.idTopic]"
      *ngIf="topic"
      class="col-12 rounded rounded-4 mb-2 text-decoration-none"
      [ngStyle]="{
         'border': '2px solid' + topic.primaryColor,
         color: topic.primaryColor
      }">
      <div class="row">

         <div 
            class="col-12 font-weight-300 pt-3 mb-3"
            style="font-size: 3em">
            {{ index | twoNumberChar}}
         </div>
         <div class="col-12 text-end pb-3">
            <i 
               class="material-icons"
               style="font-size: 3.3em">
               chevron_right
            </i>
         </div>

      </div>
   </a>

   <a 
      [routerLink]="['/docs', idDoc]"
      *ngIf="!topic"
      class="col-12 rounded rounded-4 mb-2 text-decoration-none text-white bg-seventh">
      
      <div class="row">

         <div 
            class="col-12 font-weight-300 pt-3 mb-3"
            style="font-size: 3em">
            {{ index | twoNumberChar}}
         </div>
         <div class="col-12 text-end pb-3">
            <i 
               class="material-icons"
               style="font-size: 3.3em">
               chevron_right
            </i>
         </div>

      </div>

   </a>

   <div class="col-12 px-0 font-weight-600 text-uppercase text-black">
      {{ topic ? topic.title : 'EMPEZAR'}}
   </div>

</div>