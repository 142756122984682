import { Content } from 'src/app/models/content.model';

import { BENEFITS_C } from './benefits-c.data';
import { BENEFITS_A } from './benefits-a.data';
import { BENEFITS_B } from './benefits-b.data';
import { BENEFITS_D } from './benefits-d.data';
import { BENEFITS_E } from './benefits-e.data';
import { BENEFITS_F } from './benefits-f.data';
import { BENEFITS_G } from './benefits-g.data';
import { BENEFITS_H } from './benefits-h.data';

export const IGUALDAD_LABORAL: Content = new Content({
   idContent: 'bbb5dae49a9154e522e3069284eba26c',
   order: 1,
   title: '¿Por qué la igualdad laboral es un buen negocio para las empresas?',
   content: `
   Cada vez un mayor número de empresas consideran la igualdad de género y la no discriminación la gestión de su personal, de sus procesos e incluso con productos y servicios. Esto, además de ser una cuestión de derechos (la igualdad de oportunidades es un principio jurídico universal, cuya aplicación resulta necesaria y positiva), aporta beneficios a las empresas. Algunos de estos beneficios se detallan a continuación.
   `,
   subContents: [
      BENEFITS_A,
      BENEFITS_B,
      BENEFITS_C,
      BENEFITS_D,
      BENEFITS_E,
      BENEFITS_F,
      BENEFITS_G,
      BENEFITS_H
   ]
});