import { Content } from 'src/app/models/content.model';

export const MEDIDA_4_INTRO: Content = new Content({
   idContent: 'e3ae7a7d1305fbf169f3129fa003766a',
   order: 1,
   title: '',
   content: `
    Contar con una figura mediadora u ombudsman dentro del mecanismo para prevenir, atender y sancionar las prácticas de discriminación y violencia laboral dentro del centro de trabajo.
   `,
   subContents: []
});
