import { Content } from 'src/app/models/content.model';

export const MEDIDA_2_DESCRIPCION: Content = new Content({
   idContent: 'f3269056f499df3d7dc0bd71919d7694',
   order: 1,
   title: 'Descripción',
   content: `
    El centro de trabajo debe establecer mecanismos de reclutamiento y ascenso, dirigidos a propiciar la inclusión de las mujeres en los niveles de toma de decisión, siempre tomando en cuenta su formación profesional y experiencia y no sus características personales. De este modo se romperá el techo de cristal que existe en el acceso y ascenso de las mujeres a los puestos de mayor jerarquía, toda vez que históricamente existe una notable minoría que ocupa los puestos de toma de decisiones.
    <br><br>
    Para el cumplimiento de esta medida, en la administración pública se considerará puesto directivo en nivel ascendente a partir de una dirección de área o su homólogo.
   `,
   subContents: []
});