import { Content } from 'src/app/models/content.model';

export const TOOLS_CONTENT: Content = new Content({
   idContent: 'ed50542c6ca94fc909e8391bd59c69fc',
   order: 1,
   title: 'Herramientas de apoyo',
   content: `
   Ejemplo de acciones de corresponsabilidad en la vida laboral, familiar y personal con igualdad de oportunidades.
   <br><br>
   <div class="row">
      <div class="col-12 row">
         <div class="col-5 mx-auto">
            <app-button-url
               [url]="'http://www.gob.mx/cms/uploads/attachment/file/55296/12._Ejemplos_de_acciones_de_corresponsabilidad.pdf'"
               [title]="'Descargar'"
               [icon]="'description'">
            </app-button-url>
         </div>
      </div>
   </div>
   `,
   subContents: [
   ]
});