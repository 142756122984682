import { Content } from 'src/app/models/content.model';

import { REQUISITO_PARTICIPACION_CONTENT } from 'src/app/data/contents/como-certificar/pasos-certificar/requisito-participacion.data';
import { REQUISITOS_CRITICOS_CONTENT } from 'src/app/data/contents/como-certificar/pasos-certificar/requisitos-criticos.data'
import { REQUISITOS_GENERALES_CONTENT } from 'src/app/data/contents/como-certificar/pasos-certificar/requisitos-generales.dart';

export const STEP_1_CONTENT: Content = new Content({
   idContent: 'f6be93d82ab6e0e0ca9a416a93f955c4',
   order: 1,
   title: 'Paso 1',
   content: `Conocer los requisitos de la Norma Mexicana y realizar el Diagnóstico de Autoevaluación
   <br><br>
   La Norma Mexicana puede descargarse en las siguientes páginas 
   <br><br>
   <div class="row d-flex justify-content-between">

      <div class="col-12 mb-3">
         <div class="row">
            <div class="col-12 col-md-8 col-xl-5 mx-auto">
               <app-button-url
                  [url]="'https://bit.ly/1WxoLhk'"
                  [title]="'INMujeres'"
                  [icon]="'link'">
               </app-button-url>
            </div>
         </div>
      </div>

      <div class="col-12 mb-3">
         <div class="row">
            <div class="col-12 col-md-8 col-xl-5 mx-auto">
               <app-button-url
                  [url]="'http://www.gob.mx/cms/uploads/attachment/file/55286/4._Ap_ndice_C_Diagn_stico_de_autoevaluaci_n.pdf'"
                  [title]="'Diagnostico de autoevaluación'"
                  [icon]="'link'">
               </app-button-url>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="row">
            <div class="col-12 col-md-7 col-lg-6 col-xl-5 mx-auto">
               <app-button-url
                  [url]="'https://bit.ly/1LXtOTP'"
                  [title]="'CONAPRED'"
                  [icon]="'link'">
               </app-button-url>
            </div>
         </div>
      </div>

   </div>
   <br>
   Existen tres tipos de requisitos:
   <ul>
      <li>Requisitos de participación o administrativos</li>
      <li>Requisitos de certificación o críticos</li>
      <li>Requisitos generales de certificación o no críticos</li>
   </ul>
   `,
   subContents: [
      REQUISITO_PARTICIPACION_CONTENT,
      REQUISITOS_CRITICOS_CONTENT,
      REQUISITOS_GENERALES_CONTENT,
   ]
});