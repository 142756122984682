import { Content } from "src/app/models/content.model";

export const FAQ8: Content = new Content({
    idContent: '17677a1b5f8c1816c2cfec35580967a1',
    order: 8,
    title: '¿Qué beneficios trae consigo la certificación en la Norma Mexicana?',
    content: `
        Algunos de los beneficios para el centro de trabajo son los siguientes:
        <br><br>
        <ul>
            <li>Ratificar el compromiso del centro de trabajo con la sociedad al respetar los derechos laborales de su personal y promover buenas prácticas con proveedores, actores clave y personas beneficiarias.</li>
            <li>Obtener mayor impacto en la cadena de valor del centro de trabajo.</li>
            <li>Obtener puntos en el caso de licitación pública para la adquisición de bienes, arrendamientos o servicios que utilicen la evaluación de puntos y porcentajes, en los términos del artículo 14 de la Ley de Adquisiciones, Arrendamientos y Servicios del Sector Público, vigente.</li>
            <li>Fortalecer la pertenencia, lealtad y compromiso con el centro de trabajo por parte del personal.</li>
            <li>Consolidar la cultura directiva y laboral en la que se respeta la diversidad y se proporciona igualdad de trato, de oportunidades, de remuneración y de beneficios.</li>
            <li>Reafirmar su compromiso con el personal al contar con prácticas laborales que facilitan la corresponsabilidad entre la vida laboral, familiar y personal que fortalecen el desempeño y la lealtad.</li>
            <li>Aportar mayor confianza e interés en el centro de trabajo para atraer talentos.</li>
        </ul>
        <br>
        Para las empleadas y los empleados, algunos beneficios son:
        <br><br>
        <ul>
            <li>El ejercicio más amplio de sus derechos laborales, afirmando la práctica de la igualdad en el centro de trabajo.</li>
            <li>El incremento en la confianza y credibilidad en la organización promoverá un clima laboral en igualdad de oportunidades y de trato para todas las personas.</li>
            <li>El fortalecimiento de su desarrollo personal y profesional.</li>
        </ul>
        <br>
        Por otra parte, la sociedad en general también se beneficiará:
        <br><br>
        <ul>
            <li>Con el fortalecimiento de los valores y derechos humanos que aplicarán y promoverán indirectamente los centros de trabajo, las empleadas y empleados.</li>
            <li>Se identificará el compromiso de los centros de trabajo con la igualdad laboral y la no discriminación.</li>
            <li>Se contará gradualmente con mayor número de locales e instalaciones accesibles para personas con discapacidad y personas adultas mayores.</li>
        </ul>
        Para mayor referencia, se sugiere consultar el Apéndice Normativo F de la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación.
    `,
    subContents: []
});
