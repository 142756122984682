<div 
   class="container-fluid"
   *ngIf="document && currentTopic != ''">
   <div 
      class="row"
      [ngClass]="{
         'd-flex justify-content-between': previousPage
      }">
      
      <a
         [routerLink]="[previousPage.url]"
         *ngIf="previousPage" 
         class="col-12 col-md-5 mb-4 mb-md-0 rounded rounded-4 text-decoration-none border border-2 border-primary text-fourth py-2 px-3 d-flex justify-content-between">
         <div class="d-flex align-items-center">
            <i class="material-icons">chevron_left</i>
         </div>
         <div class="text-end">
            <div class="title font-weight-600">
               {{ previousPage.title }}
            </div>
            <div class="subtitle font-weight-200">
               <!-- {{ previousPage.subTitle }} -->
            </div>
         </div>
      </a>

      <a
         [routerLink]="[nextPage.url]"
         *ngIf="nextPage"  
         class="col-12 col-md-5 rounded rounded-4 text-decoration-none text-white bg-primary py-2 px-3 d-flex justify-content-between"
         [ngClass]="{'offset-md-7': !previousPage}">
         <div class="">
            <div class="title font-weight-600 w-100">
               {{ nextPage.title }}
            </div>
            <div class="subtitle font-weight-200 w-100">
               <!-- {{ nextPage.subTitle }} -->
            </div>
         </div>
         <div class="d-flex align-items-center">
            <i class="material-icons">chevron_right</i>
         </div>
      </a>

   </div>
</div>